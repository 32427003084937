<div class="container">
  <div class="flex-row-between-center">
      <div class="">
          <h1 class="top-padding-lg" style="color: var(--color-pure-black);">Connect with me</h1>
          <div class="personal-links display-flex flex-align-center">
              <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank" >LinkedIn</a>
              <div class="separator"></div>
              <a class="visitme_link" href="https://www.behance.net/marikimikurata" target="_blank" >Behance</a>
          </div>

      </div>
      <div class="contact-image">
          <img class=" image-contain"    src="../../assets/images/about/wacom-pen.png" alt="wacome-pen">

      </div>
  </div>
  <P class="quote bottom-padding-sm">“Design is a funny word. Some people think design means how it looks. But of course, if you dig deeper, it’s really how it works.”</P>
  <p class="text-right bottom-padding-lg" style="color:var(--color-neutral-medium);">– STEVE JOBS</p>
</div>