<div class="logo">
    <a class="branding" routerLink="/" >
        <img class=" image-contain"    src="../../assets/images/navbar/klogo.png" alt="K logo">
    </a>
</div>
<div class="comp-work-intro position-rel">
    <div class="position-rel">

        <div class="comp-intro-l">
            <div class="comp-intro-titles-box flex-row-center-start-nowrap">
                <h1 class="comp-intro-title-text">Compostino</h1>
            </div>
            <div class="comp-home-top-horizonal-line"></div>
            <p class="comp-intro-subtitle-text text-highlight">Keep food scraps out of landfills</p>
        </div>
        <div class="comp-intro-r ">
            <div class="comp-hero-container">        
                <img  class="image-contain comp-hero-img" src="../../assets/images/compostino/comp-intro-hero.png">
            </div>
        </div>
    </div>
    <div class="comp-intro-life-img-box"></div>
</div>



<!-- ROLE -->
<div class="white-bg">
    <div class="container">
        <div class="grid top-padding-md bottom-padding-md">
            <div class="role-l col-sm-12 col-lg-6  ">
                <div class="tins-role-img">
                    <img  class="image-contain " src="../../assets/images/compostino/comp-role-hero.png">
                </div>
            </div>
            <div class="role-r col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="comp-separator-role"></div>  
                    <div class="role-text-box top-padding-md bottom-margin-md">
                        <h4 class="" >Overview</h4>
                        <p class="body_text_sm bottom-padding-md">Only 4 % of food waste in the US is turned into compost. Compostino is a non-profit organization that aims to educate younger generations about waste pollution and alternatives to reduce it.</p>
                        <h4 class="" >My Role</h4>
                        <p class="body_text_sm">Create a brand for Compostino with a full logo, typography, color, and assets. Help the non-profit organization design an interactive iPad app to teach kids from 8 to 12 years about the landfill problem.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Sticky progress bar with jump links and shrink effect on scroll  

tutorial links: 
        sticky bar      ------------           
        https://www.w3schools.com/howto/howto_js_navbar_sticky.asp
         https://therichpost.com/angular-8-sticky-navbar-working-example/
        shrink a header in scroll   -------     https://www.w3schools.com/howto/howto_js_shrink_header_scroll.asp
        jumping links         ---------         https://www.bennadel.com/blog/3402-creating-a-jump-to-anchor-fragment-polyfill-in-angular-5-2-0.htm   

-->
<div class="progress_bar_box">
    <h2 class="h2title stiky_nav_title top-padding-md">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <a href="#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title"> Research</p>
            </div>
        </a>
        <a href="#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">Ideation</p>
            </div>
        </a>
        <a href="#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">UI Design</p>
            </div>
        </a>
        <a href="#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Branding</p>
            </div>
        </a>
    </nav>
</div>

<!-- Section 1 -->
<div class="white-bg" id="section1">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num1">1</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">The target</p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Audience</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
                <div class="carousel" data-timer="3"> <!-- change this value to control picture holding period -->
                    <div class="carousel-deck">
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                    </div>
                </div>
                <p class="top-padding-sm ">Dear parents, thank you for your collaboration in creating the persona. Your participation in the survey was critical to this project.</p>
            </div>
        </div>
    </div>
</div>
<div  class="white-bg">
    <div class="container top-padding-md bottom-padding-md white-bg">
        <h3 class="bottom-padding-md text-highlight">Market statistics</h3>
        <div class="grid gap">
            <div class="comp-market-l col-sm-12 col-lg-6">
                <img  class="image-contain " src="../../assets/images/compostino/comp-piechart.png">
            </div>
            <div class="comp-market-r col-sm-12 col-lg-6 red">
                <div class="pie-chart-text-box ">
                    <div class="comp-separator-market "></div> 
                    <div class="market-text-container">
                        <h4 class="market-title">TOP 3 screen time activities:</h4>
                        <p>53% TV/Videos</p>
                        <p>31% Gaming</p>
                        <p>05% Browsing websites</p>
                    </div>
                </div>
            </div>
            <div class="market-card col-sm-12 col-lg-4 ">
                <p class="market-title">  More Than</p>
                <h1 class="market-num-a market-doble-outline-a">50%</h1>
                <p class="text-center">of all kids aged 6-11 use their own tablet at home.</p>
            </div>
            <div class="market-card col-sm-12 col-lg-4 ">
                <p class="market-title"> Kids from</p>
                <div class="flex-row-center-center-nowrap" > 
                    <h1 class="market-num-b market-doble-outline-b">9-11</h1>
                    <p class="text-bold market-unit-b">y.o.</p>
                </div>
                <p class="text-center">are more likely to use a tablet provided by the school.</p>
            </div>

            <div class="market-card-c col-sm-12 col-lg-4 ">
                <p class="market-title"> Kids spend</p>
                <div class="flex-row-center-center-nowrap" > 
                    <h1 class="market-num-c market-doble-outline-c">5</h1>
                    <p class="text-bold market-unit-c">h</p>
                </div>
                <p class="text-center">a day in front of digital screens.</p>
            </div>
            
        </div>

        <div>
            <a target="_blank" href="https://www.commonsensemedia.org/sites/default/files/research/report/2019-census-8-to-18-full-report-updated.pdf"><p class=" source-text">019-census-8-to-18-full-report</p></a>
            <!-- <a target="_blank" href="https://0-reports-mintel-com.library.academyart.edu/display/943488/"><p class=" source-text">  Families and Technology - US - February 2019 by MINTEL </p></a> -->
        </div>
    </div>    
</div>
<!-- Problem statement  -->
<div class="color-bg">
    <div class="container">
        <div class="grid">
            <div class="problem-statement-box col-sm-12 bottom-margin-lg top-margin-lg">
                <h4 class="text-highlight">Problem Statement</h4>
                <div class="statement-wrap">
                    <div class="quote-l">
                        <img  class="quote-l-img " src="../../assets/images/wizapp/top-quote.png">
                    </div>
                    <div>
                        <p class="statement-text text-highlight">How might we use digital devices to keep kids interested during the long process of turning food waste into compost?</p>
                    </div>
                    <div class="quote-r">
                        <img  class="quote-r-img " src="../../assets/images/wizapp/bottom-quote.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Subtitle 2-->
<div id="section2" class="comp-section2-bg">
    <div class="container">
        <div class="grid gap">
            <div class="comp-section-numb-2-r col-sm-12 col-lg-10 top-margin-lg">
                <h4 class="bottom-padding-sm">The moodboard</h4>
                <div class="moodboard" >
                    <img  class="image-contain " src="../../assets/images/compostino/comp-moodboard.png">
                </div>
                <h4 class="top-padding-md bottom-padding-sm">The word list </h4>
                <div class="moodboard" >
                    <img  class="image-contain " src="../../assets/images/compostino/comp-wordlist.png">
                </div>
                <h4 class="top-padding-md  bottom-padding-sm">Preliminary user flow</h4>
                <div class="moodboard" >
                    <img  class="image-contain " src="../../assets/images/compostino/comp-userflow.png">
                </div>
            </div>
            <div class="comp-section-numb-2-l col-sm-12 col-lg-2">
                <h1 class="comp-num2">2</h1>
                <div class="position-rel">
                    <div class="comp-nub2-titles-box">
                        <p class="comp-num2-subtitle text-highlight ">Getting inspired</p>
                        <div class="comp-num2-horizonal-line"></div>
                        <h2 class="comp-num2-title">Ideation</h2>
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</div>
<div class="comp-life-img-box"></div>


<!-- section3 -->
<div id="section3" class="white-bg bottom-padding-md">
    <div class="container">
        <div class="comp-section-numb-3-l ">
            <h1 class="comp-num3">3</h1>
            <div class="">
                <div class="comp-nub3-titles-box">
                    <p class="comp-num3-subtitle text-highlight ">The UI style & visual</p>
                    <div class="comp-num3-horizonal-line"></div>
                    <h2 class="comp-num3-title">Explorations</h2>
                </div>
            </div>
        </div>
        <div class="comp-section-numb-3-r ">
            <p class="">I started designing the application UI by distributing the main elements in the given space, considering how users interact with each piece. After completing the first  UI design round, I looked for parents willing to participate in a feedback program to gather some feedback. The main insights from the research are the following:</p>
            <ul>
                <li>Is this an app for kinder garden kids?</li>
                <li>The save button needs to be closer to the toolbar.</li>
                <li>The canvas space is big enough.</li>
            </ul>
            <p class="">This feedback allowed me to change the navbar orientation and the assets style:</p>
        </div>  
        <div class="top-margin-md" fxLayout="column" fxLayoutAlign="center center">
            <div class="inner" >
                           
                <!-- COMPARISON SLIDER CODE START -->
                
                <div class="comparison-slider-wrapper">
                   <!-- Comparison Slider - this div contain the slider with the individual images captions -->
                    <div class="comparison-slider">
                       <div class="overlay">And I am the <strong>after</strong> image.</div>
                    <img class="" src="../../assets/images/compostino/compostino-after.png" alt="after">
                    <!-- Div containing the image layed out on top from the left -->
                    <div class="resize">
                        <div class="overlay">I am the <strong>before</strong> image.</div>
                       <img class="" src="../../assets/images/compostino/compostino-before.png" alt="before">
                    </div>
                    <!-- Divider where user will interact with the slider -->
                    <div class="divider"></div>
                   </div>
                   <!-- All global captions if exist can go on the div bellow -->
                   <h1 class="top-margin-xs text-center text-secondary" style="margin-left: 3px; margin-block-end: 0em;" > &#8593;</h1>
                   <p class="text-center" >Drag this line to the right and left</p>
                </div>
                
                <!-- COMPARISON SLIDER CODE END -->
            </div>
        </div>
    </div>
</div>
<!-- sourse from https://codepen.io/karinmini/pen/wvXdRPK -->

<div class="white-bg">
    <div class="container">
        <h4 class="bottom-padding-sm">Iconography Explorations</h4>
        <p>During the design phase of this project, I created a set of outlined icons hoping to use them in the UI. However, these icons weren't working as I imagined; they needed to match the logo style better, and  I could give the screen's main buttons more visual weight with a filled icon style. </p> 
        <div class="grid gap top-margin-md bottom-padding-md">
            <div class=" col-lg-5 col-sm-5 " >
                <div class="outline">
                    <img  class="image-contain " src="../../assets/images/compostino/comp-outline-icons.png">
                </div>
            </div>
            <div class=" col-lg-2">
                <h1 class="vs-text vs-doble-outline">VS</h1>

            </div>
            <div class=" col-lg-5 col-sm-5 ">
                <div class="fill">
                    <img  class="image-contain " src="../../assets/images/compostino/comp-fill-icons.png">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- app section -->
<div class="comp-app-sec-bg">
    <div class="grid">
        <div class="comp-app-l col-sm-12 ">
                <h4 class="app-title ">126</h4>
                <h4 class="app-subtitle text-highlight">billion lbs</h4>
                <p class="app-text">of food were thrown away in 2018 in the US.</p>
                <!-- <img  class="image-contain" src="../../assets/images/tint/tint-web-hero.png"> -->
        </div>
        <div class="comp-app-r col-sm-12 ">
            <img  class="image-contain " src="../../assets/images/compostino/comp-app-highfidelity.png">
        </div>
    </div>
</div>


<!-- Section 4 -->
<div class="white-bg" id="section4">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num4">4</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">Brand identity &</p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">UI  guidelines</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
                <div class="carousel2" data-timer="3"> <!-- change this value to control picture holding period -->
                    <div class="carousel2-deck">
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="white-bg"> -->
    <div id="section5" class="final-secttion ">
        <div class="container">
            <div class="flex-row bottom-margin-sm">
                <div class="app-box ">
                    <img  class="image-contain " src="../../assets/images/compostino/app-front-hero.png">
                </div>
                <h2 class=" comp-f-section-title text-highlight">Final result</h2>
            </div>
            <h3 class="comp-f-subtitle text-highlight ">Download and play</h3>
            <p class="comp-f-text">Keep food scraps out of landfills.</p>
            <div class="comp-download-img top-margin-xs ">
                <img  class="" src="../../assets/images/compostino/download-buttons.png">
            </div>
        </div>
        
    </div>

    <!-- foolter -->
    <div class="comp-footer-bg ">
        <div class="container flex-row-between-center comp-footer-box">
            <div class="social-media flex-row-center-center bottom-padding-md">
                <p class="myname-footer">Kimi Kurata</p>
                <hr class="footer-separator-a"/>
                <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
                <hr class="footer-separator-b"/>
                <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
            </div>
            <div class="comp-footer-vl flex-row-start-end">
                <div class="vl-gradient comp-vlfooter"></div>
                <p class="body_text_xsm veritical-text bottom-padding-md comp-copyright">&copy;copyright 2022 </p>
            </div>
        </div>
    </div>
<!-- </div> -->





