<div class="logo">
    <a class="branding" routerLink="/" >
        <img class=" image-contain"    src="../../assets/images/navbar/klogo.png" alt="K logo">
    </a>
</div>
<div class="succ-work-intro position-rel">
    <div class="position-rel">

        <div class="succ-intro-l">
            <div class="succ-intro-titles-box flex-row-center-start-nowrap">
                <h1 class="succ-intro-title-text">Succulentina</h1>
            </div>
            <div class="home-top-horizonal-line"></div>
            <p class="succ-intro-subtitle-text text-highlight">Paint your life green with succulents</p>
        </div>
        <div class="succ-intro-r ">
            <div class="succ-hero-container">        
                <img  class="image-contain succ-hero-img" src="../../assets/images/succulentina/succ-intro-hero.png">
            </div>
        </div>
    </div>
    <div class="succ-intro-life-img-box"></div>
</div>
<div class="white-bg">
    <div class="container">
        <div class="grid role-spaces">
            <div class="role-l col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="separator-role"></div>  
                    <div class="role-text-box ">
                        <h4 class="" >Overview</h4>
                        <p class="body_text_sm bottom-padding-md">It’s said that engineering and design are entirely different disciplines, but they intersect inevitably. This project was intended to take me through the engineering process of building an online store and learn what it takes to bring a design to life.</p>
                    </div>
                </div>
            </div>
            <div class="role-r col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="role-text-box">
                        <h4 class="" >My Role</h4>
                        <p class="body_text_sm">My role on this project was to design and build a functional online purchase experience taking a project from rapid sketching to a fully coded prototype. In parallel, I was also required to develop and maintain a coded UI framework with reusable components for future expansions.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sticky progress bar with jump links and shrink effect on scroll  

tutorial links: 
        sticky bar      ------------           
        https://www.w3schools.com/howto/howto_js_navbar_sticky.asp
         https://therichpost.com/angular-8-sticky-navbar-working-example/
        shrink a header in scroll   -------     https://www.w3schools.com/howto/howto_js_shrink_header_scroll.asp
        jumping links         ---------         https://www.bennadel.com/blog/3402-creating-a-jump-to-anchor-fragment-polyfill-in-angular-5-2-0.htm   

-->
<div class="progress_bar_box">
    <h2 class="h2title stiky_nav_title top-padding-md">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <a href="#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title"> Sitemap</p>
            </div>
        </a>
        <a href="#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">Wireframes</p>
            </div>
        </a>
        <a href="#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">Coding</p>
            </div>
        </a>
    </nav>
</div>

<!-- Section 1 -->
<div class="white-bg" id="section1">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-3">
                <h1 class="num1">1</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">Information</p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Architecture</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-9 top-margin-lg">
    
                <p class="top-padding-sm ">I want to start this project story with the creation of the sitemap of the website. This diagram helped me visualize the project size and set the ideal scope for four months.  </p>
               
                <div class="top-margin-md" >
                    <div class="image-tag-wrap">
                        <div class="separator-img-tag"></div>  
                        <p class="img-tag-text">Site map</p>
                    </div>
                    <img  class="image-contain " src="../../assets/images/succulentina/succulentina-sitemap.png">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- succulentina section 2 -->
<div id="section2" class="section2-bg">
    <div class="container">
        <div class="grid ">

            <div class="succ-section-numb-2-r top-margin-md">
                <p class="bottom-padding-md">During this process, I dedicated extra time to think about the site's responsive layout and designing the ideal website skeleton. Getting familiar with technical terminologies like flexbox rules and grid breakpoints was a key factor during this task. I initiated the design exploration with low-fidelity mockups and spent time generating all the UI assets.</p>
                <div>
                    <img  class="image-contain " src="../../assets/images/succulentina/succulentina-wireframes.png">
                </div>
            </div>
            <div class="succ-section-numb-2-l ">
                <h1 class="succ-num2">2</h1>
                <div class="position-rel">
                    <div class="succ-nub2-titles-box">
                        <p class="succ-num2-subtitle text-highlight ">Designing</p>
                        <div class="succ-num2-horizonal-line"></div>
                        <h2 class="succ-num2-title">Grid & layout</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="white-bg">
    <div class="container ">
        <div>
            <img  class="image-contain " src="../../assets/images/succulentina/succ-high-mocks.png">
        </div>
    </div>
</div>
<div class="white-bg top-padding-md bottom-padding-md">
        <div>
            <img  class="image-contain " src="../../assets/images/succulentina/succ-mob-highmocks.png">
        </div>
</div>
<!-- Subtitle 3 succ -->
<div id="section3" class="succ-section3-bg top-padding-md">
    <div class="container">
      
            <div class="succ-section-numb-3-l ">
                <h1 class="succ-num3">3</h1>
                <div class="">
                    <div class="succ-nub3-titles-box">
                        <p class="succ-num3-subtitle text-highlight ">Coding &</p>
                        <div class="succ-num3-horizonal-line"></div>
                        <h2 class="succ-num3-title">Development</h2>
                    </div>
                </div>
            </div>
            <div class="succ-section-numb-3-r grid gap">
                <div class="sect3-l ">
                    <p class="text-bold">The style guide</p>
                    <p>After completing two design cycles, I immediately translated the visual design into CSS styles and repeatable components. During this process, I took the opportunity to create a design style guide thinking about the scalability of the project and theming customization. </p>
                    <div>
                        <img  class="image-contain " src="../../assets/images/succulentina/gudelines-css.png">
                    </div>
                </div>
                <div class="sect3-r ">
                    <div class="top-margin-sm ">
                        <img  class="image-contain " src="../../assets/images/succulentina/succ-styles.png">
                    </div>
                </div>
            </div>
    </div>
    <div class="grid">
        <div class="sect32-l ">
            <div class="top-padding-sm ">
                <img  class="image-contain " src="../../assets/images/succulentina/succ-mob-devices.png">
            </div>
        </div>
        <div class="sect32-r ">
            <p class="text-bold">Planning the database structure</p>
            <p class="">I used My sequel during this project and learned how to organize the needed information, such as product information and product lists. I never imagined that I would have to create a table for the cart information. My big takeaway from this project is that even a well-organized database with only three tables could feel like tangled Christmas lights.</p>
        </div>
    </div>
    <div class="container">
        <p class="text-bold top-padding-sm">Connecting the front-end with the back-end</p>
        <p class="bottom-padding-sm">To finalize this project, I coded the user interface according to the high-fidelity mockups and connected the database with functions to populate it. Not only did I code the product, but I also wrote end-to-end tests to simulate a purchase experience.</p>
    </div>
    <div class="container grid gap bottom-padding-md">
        <div class="back-end-l col-sm-12 col-lg-6">
            <div class="code-box">
                <img  class="image-contain " src="../../assets/images/succulentina/succ-code-snapshot.png">
            </div>
        </div>
        <div class="back-end-r col-sm-12 col-lg-6">
            <div class="">
                <img  class="image-contain " src="../../assets/images/succulentina/succ-shopping-cart.png">
            </div>
        </div>
    </div>
</div>


<!-- Section 4 -->
<div id="section4" class="white-bg " >
    <div class="container">
        <h4 class="text-center top-padding-md">The final result</h4>
        <div class="top-margin-md" >
            <img  class="image-contain " src="../../assets/images/succulentina/succ-final-hiro-a.png">
        </div>
        <a href="http://kimikurata.com/aau/wnm608/kurata.kimi/" target="_blank"><p class="text-bold text-center p-0-margin">Take me to the live website &#8594;</p></a>
    </div>
</div>
<div class="white-bg" >
    <div class="grid top-padding-sm ">
        <div class="phones-hero col-sm-12 col-lg-5 ">
            <img  class="image-contain white-bg " src="../../assets/images/succulentina/succ-final-hiro-b.png">
        </div>
        <div class="life-hero col-sm-12 col-lg-6">
            <img  class="image-contain " src="../../assets/images/succulentina/succ-final-hiro-c.png">
        </div>
    </div>
</div>
<!-- foolter -->
<div class="footer-bg white-bg">
    <div class="container flex-row-between-center succ-footer-box">
        <div class="social-media flex-row-center-center bottom-padding-md">
            <p class="myname-footer">Kimi Kurata</p>
            <hr class="footer-separator-a"/>
            <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
            <hr class="footer-separator-b"/>
            <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
        </div>
        <div class="succ-footer-vl flex-row-start-end">
            <div class="vl-gradient succ-vlfooter"></div>
            <p class="body_text_xsm veritical-text bottom-padding-md succ-copyright">&copy;copyright 2022 </p>
        </div>
    </div>
</div>