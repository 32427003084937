<div class="footer-bg">
    <div class="container flex-row-between-end ">
        <div class="social-media flex-row-center-center bottom-padding-md">
            <p class="myname-footer">Kimi Kurata</p>
            <hr class="footer-separator-a"/>
            <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
            <hr class="footer-separator-b"/>
            <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
        </div>
        <div class="flex-row-start-end">
            <div class="vl-gradient vlfooter"></div>
            <p class="body_text_xsm veritical-text bottom-padding-md copyright">&copy;copyright 2022 </p>
        </div>
    </div>
</div>