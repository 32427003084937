<div class="logo">
    <a class="branding" routerLink="/" >
        <img class=" image-contain"    src="../../assets/images/navbar/klogo.png" alt="K logo">
    </a>
</div>
<div class="introduction-box white-bg">
    <div class="container introduction-spacer">  
        <div class="intro-top-box">
            <h1 class="introduction-word-1">Design</h1>
            <h1 class="introduction-word-2">with</h1>
        </div> 
    </div>
    <div class="home-top-horizonal-line"></div>
    <div class="container">
        <div class="introduction-img-box">           
            <img  class="image-contain introduction-img" src="../../assets/images/home/innovation.png" alt="introduction title image">
        </div>
    </div>
    <div class="container grid top-margin-md">
        <div class="introduction-slogan ">
            <div class="slogan-box">
                <div class=" separator"></div>
                <p class=" introduction-text">Solutions where design and engineering meet.</p>
            </div>
        </div>
    </div>
    

    <!-- Section TINT -->
    <div class="tint-project-box ">
        <div class="grid">

            <div class="tint-container-r col-sm-12 col-md-2 ">
                <div class="tint-container-wrap">
                    <a class="home-num2-box" routerLink="/tint">
                        <img class="image-contain "  src="../../assets/images/home/home-number1.png" alt="Project 1" >
                    </a>
                    <div class="project2-titles-container">
                        <div class="project2-titles-box">
                            <p class="text-highlight project-subtitle5">The NFT marketplace</p>
                            <div class="home-numb-horizonal-line"></div>
                            <h2 class="project-title5">TINT</h2>
                        </div>
                    </div>
                </div>
                <a class="work_link2 tint-link" routerLink="/tint">Sell an NFT &#8594;</a>
            </div>
            <div class="tint-container-l">
                <div class="tint-image_box5" >
                    <img class="image-contain tint-hero-51"  src="../../assets/images/home/tint12b.png" alt="iphone image" > 
                    <a routerLink="/tint"><img class="image-contain"  id="tintanimated" data-aos="fade-down"  data-aos-duration="1000" data-aos-anchor=".tint-container-l" src="../../assets/images/home/tint11b.png" alt="tint app" ></a>   
                </div> 
            </div>
        </div>
    </div>


    <!-- Section wiz -->
    <div class="wiz-bg"> 
        <div class=" ">
            <div class="grid wiz-img-wrap">
                <a class="image_box3 col-sm-12 col-md-5" routerLink="/wizapp">
                    <img class="image-contain workimage32"  src="../../assets/images/home/wizappc12.png" alt="ti calcuator" >
                    <img class="image-contain"  id="wizanimated" data-aos="fade-down"  data-aos-duration="1000" data-aos-anchor-placement="top-center" src="../../assets/images/home/wizappc11.png" alt="wiz app" >
                </a> 
                <div class="number2-wrap col-sm-12 col-md-5">
                    <div class="content-box  content-box3 flex-row">
                        <div class="home-img1-box">
                            <a routerLink="/wizapp"><img class="image-contain home-number-1"  src="../../assets/images/home/home-number2.png" alt="Project 1" ></a>
                        </div>
                        <div class="project1-titles-container ">
                            <div class="project1-titles-box ">
                                <p class="text-highlight project-subtitle3">Your smart home lights</p>
                                <div class="home-numb-horizonal-line"></div>
                                <h2 class="project-title3">WIZ / APP</h2>
                            </div>
                        </div>
                    </div>
                    <a class="work_link2  wiz-link" routerLink="/wizapp">&#8592; Turn the lights on </a>
                </div> 
            </div>
        </div>
    </div>
</div>

    

    <div class="meddile-bg">
        <!-- section compostino -->
        <div class="top-padding-lg bottom-padding-lg ">
            <div class="grid">
                <div class="compo-container-l col-sm-12 col-md-5 ">
                    <div class="project6-titles-box">
                        <p class="text-highlight project-subtitle6">Keep food scraps out of landfills</p>
                        <div class="home-numb-horizonal-line"></div>
                        <h2 class="project-title6">Compostino</h2>
                    </div>
                    <a class="home-img6-box" routerLink="/compostino">
                        <img class="home-number-3"  src="../../assets/images/home/home-number3.png" alt="Project 1" >                    
                    </a>
                    <div class="link6-box">
                        <a class="work_link2" style="margin-left:0px;" routerLink="/compostino">Make compost &#8594;</a>
                    </div>
                </div>
                <div class="compo-container-r col-sm-12 col-md-7">
                    <div class="position-abs resposive-outline">
                        d
                    </div>
                    <div class="position-abs resposive-bg">
                        d
                    </div>
                    <div class="compo-image_box6" >
                        <img class="image-contain compo-hero-61"  src="../../assets/images/home/compostinob14.png" alt="ti calcuator" >
                        <a routerLink="/compostino"><img class="image-contain"  id="compoanimated" data-aos="fade-right"  data-aos-duration="1000" data-aos-anchor-placement="top-center" src="../../assets/images/home/compostinob11.png" alt="ti calcuator" ></a>
                    </div>  
                </div>
            </div>
        </div>
    

        <!--Secction Succulentina -->
        <div class="top-padding-sm bottom-padding-lg">
            <div class="grid">
                <div class="succ-container-l col-sm-12 col-md-7">
                    <div class="position-abs resposive-outline7">d</div>
                    <div class="position-abs resposive-bg7">d</div>
                    <div class="succ-image_box7 " >
                        <img class="image-contain succ-hero-71"  src="../../assets/images/home/succulentina12.png" alt="ti calcuator" >
                        <a routerLink="/succulentina" ><img class="image-contain"  id="succanimated" data-aos="fade-right"  data-aos-duration="1000" data-aos-anchor-placement="top-center" src="../../assets/images/home/succulentina11.png" alt="ti calcuator" ></a>
                    </div>  
                </div>
                <div class="succ-container-r col-sm-12 col-md-5">
                    <div class="project7-titles-box">
                        <p class="text-highlight project-subtitle7">Paint your life green with succulents</p>
                        <div class="home-numb-horizonal-line"></div>
                        <h2 class="project-title7">Succulentina</h2>
                    </div>
                    <div class="home-img7-box">
                        <a routerLink="/succulentina"><img class="home-number-4"  src="../../assets/images/home/home-number4.png" alt="Project 1" ></a>
                    </div>
                    <a class="work_link2 link7" style="margin-left:00px;" routerLink="/succulentina">&#8592; Find a plant</a>
                </div>
            </div>
        </div>
    </div>


    <!-- Section TI nspire -->
    <div class="nsp-bg top-padding-lg bottom-padding-lg">
        <div class="grid">
            <div class="nsp-container-l col-sm-12 col-md-5 ">
                <div class="project8-titles-box ">
                    <p class="text-highlight project-subtitle8">The mission to teach science</p>
                    <div class="home-numb-horizonal-line"></div>
                    <h2 class="project-title8">TI-NSpire</h2>
                </div>
                <a class="home-img8-box " routerLink="/tinspirecalc">
                    <img class="home-number-5"  src="../../assets/images/home/home-number5.png" alt="Project 1" >
                </a>
                <div class="link8-box">
                    <a class="work_link2" style="margin-left:0px;" routerLink="/tinspirecalc">Teach math &#8594;</a>
                </div>
            </div>
            <div class="nsp-container-r col-sm-12 col-md-7 ">
                <div class="nsp-image_box8" >
                    <img class="image-contain nsp-hero-81"  src="../../assets/images/home/tinspire12.png" alt="iphone image" >
                    <a routerLink="/tinspirecalc"><img class="image-contain  "  id="nspanimated" data-aos="fade-right"  data-aos-duration="1000" data-aos-anchor-placement="top-center" src="../../assets/images/home/tinspire11.png" alt="macbook pro" ></a>
                </div> 
            </div>
        </div>
    </div>

    <!-- Clover -->
    <div class="white-bg top-padding-lg bottom-padding-lg ">
        <div class="grid position-rel">
            <div class="clov-container-l col-sm-12 col-md-7 ">
                <div class="clov-image_box9" >
                    <img class="image-contain clov-hero-91"  src="../../assets/images/home/clover12.png" alt="iphone image" >
                    <a routerLink="/clover"><img class="image-contain"  id="clovanimated" data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-center" src="../../assets/images/home/clover11.png" alt="macbook pro" ></a>
                </div> 
            </div>

            <div class="clov-container-r col-sm-12 col-md-5 ">
                <div class=" content-box9">
                    <div class="project9-titles-container ">
                        <div class="project9-titles-box ">
                            <div class="flex-row-between-center-nowrap ">
                                <p class="text-highlight project-subtitle9">Order your bread fresh  out of the oven </p>
                                <p class="body_text_xsm copyright-home">&copy;copyright 2022 </p>
                            </div>
                            <div class="home-numb-horizonal-line thik"></div>
                            <h2 class="project-title9">Clover  bakery</h2>
                        </div>
                    </div>
                    <a class="home-img9-box" routerLink="/clover">
                        <img class="image-contain"  src="../../assets/images/home/home-number6.png" alt="Project 1" >
                    </a>
                    <div class="link9-box">
                        <a class="work_link2" style="margin-left:0px;" routerLink="/clover">Order a cake&#8594;</a>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <!-- footer -->
    <div class="footer-bg top-padding-sm">
        <div class="container flex-row-between-end ">
            <div class="social-media flex-row-center-center bottom-padding-md">
                <p class="myname-footer">Kimi Kurata</p>
                <hr class="footer-separator-a"/>
                <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
                <hr class="footer-separator-b"/>
                <a class="visitme_link" href="../../assets/resume/mari_kurata_resume.pdf" download>Resume</a>
            </div>
        </div>
    </div>




    