<div class="logo">
    <a class="branding" routerLink="/" >
        <img class=" image-contain"    src="../../assets/images/navbar/klogo.png" alt="K logo">
    </a>
</div>
<div class="tint-work-intro position-rel">
    <div class="position-rel">
        <div class="tint-intro-l">
            <div class="tint-intro-titles-box flex-row-center-start-nowrap">
                <h1 class="tint-intro-title-text">TINT</h1>
            </div>
            <div class="tint-home-top-horizonal-line"></div>
            <p class="tint-intro-subtitle-text text-highlight">The NFT marketplace</p>
        </div>
        <div class="tint-intro-r ">
            <div class="tint-hero-container">        
                <img  class="image-contain tint-hero-img" src="../../assets/images/tint/tint-intro-hero.png">
            </div>
        </div>
    </div>
    <div class="tint-intro-life-img-box"></div>
</div>
<div class="white-bg">
    <div class="container">
        <div class="grid role-spaces">
            <div class="role-l col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="separator-role"></div>  
                    <div class="role-text-box ">
                        <h4 class="" >Overview</h4>
                        <p class="body_text_sm bottom-padding-md">Selling NFTs to become rich is the dream number 1 for many digital student artists. Unfortunately, today, the process of creating and selling NFTs has too many expensive fees, and only the ones who can afford them can make it to the industry without guaranteed success. </p>
                    </div>
                </div>
            </div>
            <div class="role-r col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="role-text-box">
                        <h4 class="" >My Role</h4>
                        <p class="body_text_sm">My main objective was to identify students' needs when starting a business selling NFT and providing a complementary multi-platform system to connect NFT collectors with art students.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sticky progress bar with jump links and shrink effect on scroll  

tutorial links: 
        sticky bar      ------------           
        https://www.w3schools.com/howto/howto_js_navbar_sticky.asp
         https://therichpost.com/angular-8-sticky-navbar-working-example/
        shrink a header in scroll   -------     https://www.w3schools.com/howto/howto_js_shrink_header_scroll.asp
        jumping links         ---------         https://www.bennadel.com/blog/3402-creating-a-jump-to-anchor-fragment-polyfill-in-angular-5-2-0.htm   

-->
<div class="progress_bar_box">
    <h2 class="h2title stiky_nav_title top-padding-md">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <a href="#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title"> Research</p>
            </div>
        </a>
        <a href="#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">Ideation</p>
            </div>
        </a>
        <a href="#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">User flows</p>
            </div>
        </a>
        <a href="#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Branding</p>
            </div>
        </a>
    </nav>
</div>

<!-- Section 1 -->
<div class="white-bg" id="section1">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num1">1</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">Understanding the</p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Personas</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
                <div class="carousel" data-timer="3"> <!-- change this value to control picture holding period -->
                    <div class="carousel-deck">
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                    </div>
                </div>
                <p class="top-padding-sm ">Unexpectedly art collectors struggle to find good quality art for a reasonable price. Buying NFTs from talented students is an excellent way to acquire art pieces that fit with their NFT galleries.                 </p>
            </div>
        </div>
    </div>
</div>
<div  class="white-bg">
    <div class="container top-padding-md bottom-padding-md white-bg">
        <h3 class="bottom-padding-md text-highlight">Competitive analysis</h3>
        <div>
            <img  class="image-contain " src="../../assets/images/tint/competitve-analysis.png">
        </div>
    </div>    
</div>

<!-- Problem statement  -->
<div class="color-bg bottom-margin-lg">
    <div class="container">
        <div class="grid">
            <div class="problem-statement-box col-sm-12 bottom-margin-lg top-margin-lg">
                <h4 class="">Problem statement</h4>
                <div class="statement-wrap">
                    <div class="quote-l">
                        <img  class="quote-l-img " src="../../assets/images/wizapp/top-quote.png">
                    </div>
                    <div>
                        <p class="statement-text text-highlight">Help art students to get started in the NFT world.</p>
                        <p class="statement-text text-highlight">Provide a space for digital art collectors to find amazing undiscovered artists.</p>
                        <p class="statement-text text-highlight">Create an ecosystem to connect young artists and NFT collectors.</p>    
                    </div>
                    <div class="quote-r">
                        <img  class="quote-r-img " src="../../assets/images/wizapp/bottom-quote.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Subtitle 2 tint-->
<div id="section2" class="white-bg">
    <div class="grid">
        <div class="section-numb-2-r col-sm-12 col-lg-6">
            <div class="num2-title-container">
                <h1 class="num2">2</h1>
                <div class="nub2-titles-box">
                    <p class="num2-subtitle text-highlight ">Solution</p>
                    <div class="num2-horizonal-line"></div>
                    <h2 class="num2-title">Diagram</h2>
                </div>
            </div>
        </div>
        <div class="section-numb-2-l col-sm-12 col-lg-6 ">
            <h4 class="terciary-title">NFT Display</h4>
            <p class="top-padding-sm">Think of a public space to advertise NFTs. Users should be able to interact with the display by adding likes, downloading the app, and connecting with the artist.</p>
            <h4 class="terciary-title top-padding-sm">Website</h4>
            <p class="top-padding-sm">Provide a place to create accounts, upload art, explore and purchase NFTs.</p>
            <h4 class="terciary-title top-padding-sm">Mobile app</h4>
            <p class="top-padding-sm">A downloadable mobile application to explore galleries, buy NFTs and connect with artists.</p>
        </div>
    </div>
</div>

<!-- Subtitle 3 tint-->
<div id="section3" class="tint-section3-bg">
    <div class="container">
        <div class="grid">
            <div class="section-numb-3-l col-sm-12 col-lg-4">
                <h1 class="num3">3</h1>
                <div class="position-rel">
                    <div class="nub3-titles-box">
                        <p class="num3-subtitle text-highlight ">Defining task flows &</p>
                        <div class="num3-horizonal-line"></div>
                        <h2 class="num3-title">Experiences</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-3-r col-sm-12 col-lg-8 top-margin-lg">
                <p>Once I defined the ideal ecosystem, I set the goal and experience for each device. It was essential for this project to keep all devices connected and provide a unified environment. Therefore, I focused on the following main interactions:</p>
            </div>
        </div>
        
    </div>
</div>
<div class="white-bg bottom-padding-md">
    <div class="container">
        <h4 class="text-highlight top-padding-md">Connect with your favorite artist</h4>
        <p>The kiosk that works as a bridge between art collectors and artists.</p>
        <div class="top-margin-md" >
            <div class="image-tag-wrap">
                <div class="separator-img-tag"></div>  
                <p class="img-tag-text">User flow 1</p>
            </div>
            <img  class="image-contain " src="../../assets/images/tint/tint-userflow1.png">
        </div>
    </div>
</div>
<div class="color-bg top-padding-md bottom-padding-md">
    <div class="container">
        <div class="image-tag-wrap">
            <div class="separator-img-tag"></div>  
            <p class="img-tag-text">High-fidelity mockups</p>
        </div>
        <div class="highmock1-bg " >
            <img  class="image-contain " src="../../assets/images/tint/tint-highmock-1.png">
        </div>
        <div class="flex-row-center-center-nowrap screen-names-wrap1">
            <p class="screen-title11 text-center ">Kiosk Explore</p>
            <p class="screen-title12 text-center ">Kiosk NFT page</p>
            <p class="screen-title13 text-center ">Artist links</p>
            <p class="screen-title14 text-center ">Instagram</p> 
        </div>
    </div>
</div>
<div class="tint-life2-img-box"></div>
<div class="white-bg bottom-padding-md">
    <div class="container">
        <h4 class="top-padding-md">Buy an NFT with the app</h4>
        <p>The app provides quick access to the crypto wallet.</p>
        <div class="top-margin-md " >
            <div class="image-tag-wrap">
                <div class="separator-img-tag"></div>  
                <p class="img-tag-text">User flow 2</p>
            </div>
            <img  class="image-contain " src="../../assets/images/tint/tint-userflow2.png">
        </div>
    </div>
</div>
<div class="color-bg top-padding-md bottom-padding-md">
    <div class="container">
        <div class="image-tag-wrap">
            <div class="separator-img-tag"></div>  
            <p class="img-tag-text">High-fidelity mockups</p>
        </div>
        <div class="highmock2-bg " >
            <img  class="image-contain " src="../../assets/images/tint/tint-highmock-2.png">
        </div>
        <div class="flex-row-center-center-nowrap screen-names-wrap2">
            <p class="screen-title2 text-center ">Explore</p>
            <p class="screen-title2 text-center ">NFT page</p>
            <p class="screen-title2 text-center ">Checkout</p>
            <p class="screen-title2 text-center ">Purchase notification</p> 
        </div>
    </div>
</div>
<div class="white-bg bottom-padding-md">
    <div class="container">
        <h4 class="top-padding-md text-highlight">Upload an NFT </h4>
        <p>Save the finished NFT to the platform.</p>
        <div class="top-margin-md " >
            <div class="image-tag-wrap">
                <div class="separator-img-tag"></div>  
                <p class="img-tag-text">User flow 3</p>
            </div>
            <img  class="image-contain " src="../../assets/images/tint/tint-userflow3.png">
        </div>
    </div>
</div>
<div class="web-sec-bg">
    <div class="grid">
        <div class="sectio3-nweb-l col-sm-12 ">
            <h4 class="top-padding-lg text-highlight">Log in as a student</h4>
            <p>Upload artwork and sell NFTs</p>
            <div class="top-padding-md bottom-padding-md">
                <img  class="image-contain" src="../../assets/images/tint/tint-web-hero.png">
            </div>
        </div>
        <div class="sectio3-nweb-r col-sm-12 ">
            <img  class="image-contain " src="../../assets/images/tint/tint-web-highfidelity.png">
        </div>
    </div>
</div>
<!-- Section 4 -->
<div class="white-bg" id="section4">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num4">4</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">UI guidelines &</p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Brand Identity</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
                <div class="carousel2" data-timer="3"> <!-- change this value to control picture holding period -->
                    <div class="carousel2-deck">
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                    </div>
                </div>
                <p class="top-padding-md" id="section5">The design process for this project included brand development that embraced a logo, typography, color, iconography, and background patterns. I will leave the evolution of the branding of this project for another time, but you can enjoy the final result here. </p>
            </div>
        </div>
    </div>
    <div class="grid top-margin-lg">
        <div class="tint-hero-last-wrap">
            <img  class="image-contain white-bg" src="../../assets/images/tint/tint-hiro-last.png">
        </div>
    </div>
</div>
<!-- foolter -->
<div class="footer-bg white-bg">
    <div class="container flex-row-between-center">
        <div class="tint-social-media flex-row-center-center bottom-padding-md">
            <p class="myname-footer">Kimi Kurata</p>
            <hr class="footer-separator-a"/>
            <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
            <hr class="footer-separator-b"/>
            <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
        </div>
        <div class="footer-vl flex-row-start-end">
            <div class="vl-gradient vlfooter"></div>
            <p class="body_text_xsm veritical-text bottom-padding-md copyright">&copy;copyright 2022 </p>
        </div>
    </div>
</div>