import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { HomenewComponent } from './homenew/homenew.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';

import { TinspireComponent } from './tinspire/tinspire.component';
import { TipythonComponent } from './tipython/tipython.component';
import { WizappComponent } from './wizapp/wizapp.component';
import { HolidayinnComponent } from './holidayinn/holidayinn.component';
import { TintComponent } from './tint/tint.component';
import { CompostinoComponent } from './compostino/compostino.component';
import { HomeComponent } from './home/home.component';
import { SucculentinaComponent } from './succulentina/succulentina.component';
import { TinspirecalcComponent } from './tinspirecalc/tinspirecalc.component';
import { CloverComponent } from './clover/clover.component';


const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  useHash: true,
};


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'homenew', component: HomenewComponent},
  {path: 'about-me', component: AboutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'tinspire', component: TinspireComponent}, 
  {path: 'tipython', component: TipythonComponent}, 
  {path: 'wizapp', component: WizappComponent}, 
  {path: 'holidayinn', component: HolidayinnComponent}, 
  {path: 'tint', component: TintComponent},
  {path: 'compostino', component: CompostinoComponent},
  {path: 'content', component: WizappComponent},
  {path: 'home', component: HomeComponent},
  {path: 'succulentina', component: SucculentinaComponent},
  {path: 'tinspirecalc', component: TinspirecalcComponent},
  {path: 'clover', component: CloverComponent},
  {
    path: '',
    redirectTo: 'content', // Empty path will redirect to content route.
    pathMatch: 'full'
  }


];
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
