<div class="logo">
    <a class="branding" routerLink="/" >
        <img class=" image-contain"    src="../../assets/images/navbar/klogo.png" alt="K logo">
    </a>
</div>
<div class="tins-work-intro position-rel">
    <div class="position-rel">

        <div class="tins-intro-l">
            <div class="tins-intro-titles-box flex-row-center-start-nowrap">
                <h1 class="tins-intro-title-text">TI-NSpire
                </h1>
            </div>
            <div class="tins-home-top-horizonal-line"></div>
            <p class="tins-intro-subtitle-text text-highlight">Making math and science easier to learn.</p>
        </div>
        <div class="tins-intro-r ">
            <div class="tins-hero-container">        
                <img  class="image-contain tins-hero-img" src="../../assets/images/tinspirecalc/tin-intro-hero.png">
            </div>
        </div>
    </div>
    <div class="tins-intro-life-img-box"></div>
</div>

<!-- ROLE --> 
<div class="white-bg">
    <div class="container">
        <div class="grid top-padding-md bottom-padding-md">
            <div class="role-l col-sm-12 col-lg-6  ">
                <div class="tins-role-img">
                    <img  class="image-contain " src="../../assets/images/tinspirecalc/tins-role-hero.png">
                </div>
            </div>
            <div class="role-r col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="separator-role"></div>  
                    <div class="role-text-box top-padding-md bottom-margin-md">
                        <h4 class="" >Overview</h4>
                        <p class="body_text_sm bottom-padding-md">TI-nspire CX II is Textas Instruments' most sophisticated graphing calculator, mainly used for educational purposes. This project's objective was to increase this product's user experience by unifying the UI design inconsistencies. </p>
                        <h4 class="" >My Role</h4>
                        <p class="body_text_sm">For this project, I mostly worked on creating a product UI style guide that developers and project managers could use as a reference to build new features in the future. My participation was vital to go beyond the UI changes and fixing known user experience issues like the one presented on this page.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Sticky progress bar with jump links and shrink effect on scroll  

tutorial links: 
        sticky bar      ------------           
        https://www.w3schools.com/howto/howto_js_navbar_sticky.asp
         https://therichpost.com/angular-8-sticky-navbar-working-example/
        shrink a header in scroll   -------     https://www.w3schools.com/howto/howto_js_shrink_header_scroll.asp
        jumping links         ---------         https://www.bennadel.com/blog/3402-creating-a-jump-to-anchor-fragment-polyfill-in-angular-5-2-0.htm   

-->
<div class="progress_bar_box">
    <h2 class="h2title stiky_nav_title top-padding-md">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <a href="#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title"> Research</p>
            </div>
        </a>
        <a href="#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">Analysis</p>
            </div>
        </a>
        <a href="#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">Ideate</p>
            </div>
        </a>
        <a href="#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Discuss</p>
            </div>
        </a>
        <a href="#section5" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'five'" (click)="markSelected('five')"  target="_self">
            <div class="sticky_content">
                <div class="circle">5</div>
                <p class="progress_title">Develop</p>
            </div>
        </a>
    </nav>
</div>

<!-- Section 1 -->
<div class="white-bg" id="section1">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num1">1</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">The creation of  three </p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Personas</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
                <div class="carousel" data-timer="3"> <!-- change this value to control picture holding period -->
                    <div class="carousel-deck">
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                    </div>
                </div>
                <p class="top-padding-sm ">The most interesting thing I learned about these personas is that students and teachers depend on each other. This graphing calculator is a complicated device that needs proper guidance on how to use it.</p>
            </div>
        </div>
    </div>
</div>
<div  class="white-bg">
    <div class="container top-padding-md bottom-padding-md white-bg">
        <h3 class="bottom-padding-md text-highlight">The known user stories</h3>
        <p>Hearing stories from users, teammates, and project managers, I identified the following user pain points:</p>
        <div class="flex-row-between-center">
            <div class="illustration">
                <img class="image-contain" src="../../assets/images/tinspirecalc/handheald-illustration.png" alt="experience icon" >
            </div>
            <!--CALLOUT BOX sourse https://mrcoles.com/blog/callout-box-css-border-triangles-cross-browser/#css-triangles-code -->
            <div class="experiences_list">
                <div class="callout">
                    <div class="flex-row-between-center">
                        <p class="callout-text" >The blue circle I drew during the last class disappeared.</p>
                        <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                    </div>
                    <b class="notch"></b>
                </div>
                <div class="callout">
                    <div class="flex-row-between-center">
                        <p class="callout-text" >I don’t see the y-axis and x-axis on the screen.</p>
                        <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                    </div>
                    <b class="notch"></b>
                </div>
                <div class="callout">
                    <div class="flex-row-between-center">
                        <p class="callout-text" >I tap Shift + “mode” but nothing happens.</p>
                        <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                    </div>
                    <b class="notch"></b>
                </div>
                <div class="callout">
                    <div class="flex-row-between-center">
                        <p class="callout-text" >Can’t find the option “shapes” in the menu.</p>
                        <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                    </div>
                    <b class="notch"></b>
                </div>
            </div>
        </div>
    </div>    
</div>

<!-- Problem statement  -->
<div class="section-bg">
    <div class="container">
        <div class="grid tins-pstatement-wrap">
            <div class="tins-problem-statement-box col-sm-12 bottom-margin-lg top-margin-lg">
                <h4 class="text-highlight bottom-padding-sm">Problem Statement</h4>
                <div class="statement-wrap bottom-padding-sm">
                    <div class="quote-l">
                        <img  class="quote-l-img " src="../../assets/images/wizapp/top-quote.png">
                    </div>
                    <div>
                        <p class="statement-text">The user needs help to identify the currently open app.</p>
                    </div>
                    <div class="quote-r">
                        <img  class="quote-r-img " src="../../assets/images/wizapp/bottom-quote.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Subtitle 2 tint-->
<div id="section2" class="tint-section3-bg">
    <div class="container">
        <div class="grid">
            <div class="section-numb-3-l col-sm-12 col-lg-4">
                <h1 class="num3">2</h1>
                <div class="position-rel">
                    <div class="nub3-titles-box">
                        <p class="num3-subtitle text-highlight ">User flow review and</p>
                        <div class="num3-horizonal-line"></div>
                        <h2 class="num3-title">Analysis</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-3-r col-sm-12 col-lg-8 top-margin-lg">
                <h4>Find the ideal layout to display secondary functions.</h4>
                <p>Looking for the answer to why and how the user could not identify the currently open app, I decided to check the user flow shown below. After looking at the user flow diagram, I noticed that different app screens look <span class="insight-num">95%</span> similar. In conclusion, users need an indicator to know what's the opened app. </p>
            </div>
        </div>
    </div>
</div>
<div class="white-bg bottom-padding-lg">
    <div class="container">
        <div class="image-tag-wrap">
            <div class="separator-img-tag"></div>  
            <p class="img-tag-text">UI flow</p>
        </div>
    </div>
    <div class="uiflow-box" >
        <img  class="image-contain " src="../../assets/images/tinspirecalc/tins-uiflow.png">
    </div>
    
</div>


<!-- Subtitle 3 -->
<div id="section3" class="white-bg">
    <div class="grid gap">
        <div class="section-numb-2-r col-sm-12 col-lg-6">
            <div class="num2-title-container">
                <h1 class="num2">3</h1>
                <div class="nub2-titles-box">
                    <p class="num2-subtitle text-highlight ">Low to high-fidelity mockups &</p>
                    <div class="num2-horizonal-line"></div>
                    <h2 class="num2-title">Brainstorming</h2>
                </div>
            </div>
        </div>
        <div class="tins-section-numb-2-l col-sm-12 col-lg-6 ">
            <p class="top-padding-sm">Graphing calculators have UI space and resolution limitations. I started with 3 reasonable design options: </p>
            <p class="top-padding-sm"><span class="text-bold">Idea 1:</span> Use the app icon </p>
            <p class="top-padding-sm"><span class="text-bold">Idea 2:</span> Dynamic navbar color </p>
            <p class="top-padding-sm"><span class="text-bold">Idea 3:</span>  Dynamic outline color </p>
            <p class="top-padding-sm">After discussing these options with the design team, I decided to eliminate option one and move forward. </p>
        </div>
    </div>
</div>
<div class="white-bg bottom-padding-md">
    <div class="container">
        <h4 class="top-padding-md">Design presentation and discussion with PMs</h4>
        <p>There were mainly two potential ideas that fit with the statement above. First, I created two high-fidelity mockups to show the team how they would look. Additionally, I made an accessibility test over option A (see image below) and a comparative table to describe the pros & cons of each design.</p>
        <div class="top-margin-md" >
            <div class="image-tag-wrap">
                <div class="separator-img-tag"></div>  
                <p class="img-tag-text">Design variations</p>
            </div>
            <img  class="image-contain " src="../../assets/images/tinspirecalc/tins-variations.png">
        </div>
    </div>
</div>
<div class="tins-life2-img-box"></div>


<div class="tins-section4-5-bg">

    <!-- Section 4 -->
    <div class="" id="section4">
        <div class="container">
            <div class="grid">
                <div class="tins-section-numb-4-l">
                    <h1 class="tins-num4">4</h1>
                    <div class="position-rel">
                        <div class="nub-titles-box">
                            <p class="num-subtitle text-highlight ">Discuss the pros & cons</p>
                            <div class="tins-num4-horizonal-line"></div>
                            <h2 class="num-title">Design decision</h2>
                        </div>
                    </div>
                </div>
                <div class="tins-section-numb-4-r ">
                    <p class="pros-cons-box" >Unfortunately, the color application on option A failed the accessibility test, and finally, option B was chosen as the final design solution. </p>
                    <div class="">
                        <img  class="image-contain" src="../../assets/images/tinspirecalc/tins-pros-cons.png">

                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- Subtitle 5 wiz-->
    <div id="section5"  class=""> 
        <div class="container">
            <div class="grid">
                <div class="tins-section-numb-5-l col-sm-12 col-lg-8">
                    <div class="spec-img-box1" >
                        <img  class="image-contain" src="../../assets/images/tinspirecalc/ti-specs1.png">

                    </div>
                    <div class="spec-img-box2 ">
                        <img  class="image-contain" src="../../assets/images/tinspirecalc/ti-specs2.png">

                    </div>
                </div>
                <div class="tins-section-numb-5-r col-sm-12 col-lg-4">
                    <div class="num5-title-container ">
                        <h1 class="tins-num5">5</h1>
                        <div class="nub5-titles-box">
                            <p class="num5-subtitle text-highlight ">Specs and </p>
                            <div class="num5-horizonal-line"></div>
                            <h2 class="num5-title">Development</h2>
                        </div>
                    </div>
                    <div class="section5-content-wrap">
                        <p class="bottom-padding-sm">To finalize the project, I added a new section in the product UI guidelines with the latest specs for the app indicator.
                        </p>
                        <p class="bottom-padding-sm">During the development of this product, the engineering team contacted me to discuss what to do when the screen is split into multiple apps. As a result, I added the additional specs to the UI guideline and reviewed the final result once it was ready for production. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="white-bg top-padding-md" id="section6">
    <div class="container">
        <h1>Final result</h1>
    </div>
    <div class="tins-sec-bg">
        <div class="grid">
            <div class="tins-final-l  ">
                <div class="top-padding-md bottom-padding-md">
                    <img  class="image-contain " src="../../assets/images/tinspirecalc/tins-highfidelity.png">
                </div>
            </div>
            <div class="tins-final-r  ">
                <img  class="image-contain elevation1" src="../../assets/images/tinspirecalc/tins-final-result.gif">
            </div>
        </div>
    </div>
</div>



<!-- foolter -->
<div class="footer-bg white-bg">
    <div class="container flex-row-between-center tins-footer-box">
        <div class="social-media flex-row-center-center bottom-padding-md">
            <p class="myname-footer">Kimi Kurata</p>
            <hr class="footer-separator-a"/>
            <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
            <hr class="footer-separator-b"/>
            <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
        </div>
        <div class="tins-footer-vl flex-row-start-end">
            <div class="vl-gradient tins-vlfooter"></div>
            <p class="body_text_xsm veritical-text bottom-padding-md tins-copyright">&copy;copyright 2022 </p>
        </div>
    </div>
</div>