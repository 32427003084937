<mat-toolbar>
  <div class="navbar fixed-top" >
      <div class="right-links-box ">
          <hr class="verlical-dark-ln">
          <div class="nav-title-box">
            <p class="nav-title">Kimi Kurata</p>
            <p class="nav-subtitle">UI/UX Designer</p>
          </div> 
          <div class="right-links">
            <a class="nav-link " routerLink="/home" routerLinkActive="selected">&nbsp; &nbsp; Work &nbsp; &nbsp; </a>
            <a class="nav-link " routerLink="/about-me" routerLinkActive="selected">&nbsp; &nbsp; About &nbsp; &nbsp; </a>
            <p class="nav-end-spacer">&nbsp; &nbsp; &nbsp; &nbsp; </p>
          </div>
      </div>
  </div>
</mat-toolbar>  

