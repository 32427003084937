var Carousel2 = function (el) {

	let carousel2 = this;

	carousel2.element = $(el);
	carousel2.currentSlide = 0;
	carousel2.previouseSlide = 0;
	carousel2.numberOfSlides = 0;

	carousel2.timer = false;

	carousel2.timerLength = 5000;

	carousel2.timerPause = 10000;

	carousel2.timing = false;

	carousel2.changePosition = function(direction){

		if(carousel2.timing) return;

		carousel2.previouseSlide = carousel2.currentSlide;

		if(direction === false) {
			carousel2.currentSlide--;
		} else

		if(direction === true) {
			carousel2.currentSlide++;
		} else

		{
			if(carousel2.currentSlide<direction) {
				carousel2.currentSlide = direction;

				return carousel2.showPosition(true,true);
			
			} else if (carousel2.currentSlide>direction) {
				carousel2.currentSlide = direction;
				return carousel2.showPosition(false,true);
			}
		}
		if(carousel2.currentSlide == carousel2.previouseSlide) return;

		if(carousel2.currentSlide < 0){
			carousel2.currentSlide = carousel2.numberOfSlides-1;
		}  else if(carousel2.currentSlide >= carousel2.numberOfSlides){
		  	carousel2.currentSlide = 0;
		  }

		  carousel2.showPosition(direction,false);
		};


		carousel2.showPosition = function(direction,placed){

			clearTimeout(carousel2.changeTimer);
			carousel2.changeTimer = false;

			let leftposition, rightposition;
			if(carousel2.currentSlide == 0){
				leftposition =  carousel2.currentSlide-1;
			} else leftposition = carousel2.currentSlide-1;

			if(carousel2.currentSlide == carousel2.numberOfSlides-1){
				rightposition = 0;
			} else rightposition = carousel2.currentSlide+1;

			let cs = carousel2.element.find(".carousel2-slide")
				.removeClass("atLeft atRight atCenter moving");

				cs.eq(carousel2.previouseSlide).addClass("atCenter")
				if(direction===true){
					cs.eq(carousel2.currentSlide).addClass("atRight");
					cs.eq(rightposition).addClass("atRight");
				} else if(direction===false){
					cs.eq(carousel2.currentSlide).addClass("atLeft");
					cs.eq(leftposition).addClass("atLeft");
				}

				carousel2.timing = true;

				carousel2.changeTimer = setTimeout(function(){
					carousel2.element.find(".carousel2-paginate")
					.eq(carousel2.currentSlide).addClass("active")
					.siblings().removeClass("active");
				cs.eq(carousel2.currentSlide)
					.removeClass("atLeft atRight").addClass("moving atCenter");
				cs.eq(carousel2.previouseSlide)
					.removeClass("atLeft atRight atCenter").addClass("moving at"+
						(direction===true ? "Left" : "Right"));


				setTimeout(function(){carousel2.timing = false;},1000);

			},50);
		};

		carousel2.startTimer = function(){
			if(carousel2.timerLength === 0) return;
			carousel2.timer = setInterval(carousel2.tick, carousel2.timerLength);
		};

		carousel2.stopTimer = function(){
			clearInterval(carousel2.timer);
			carousel2.timer = false;
		};

		carousel2.pauseTimer = function(){
			carousel2.stopTimer();
			carousel2.timer = setTimeout(carousel2.startTimer, carousel2.timerPause);
		};

		carousel2.tick = function(){
			carousel2.changePosition(true);
		};

		carousel2.makeButtons = function(){

			let buttondiv = $("<div class='carousel2-pagination'>");

			for(let i=0; i<carousel2.numberOfSlides; i++) {
				let btn = $("<button class='carousel2-paginate'>").html("&#x25cf;");
				if(i==0) btn.addClass("active");
				buttondiv.append(btn);
			}

			carousel2.element.append(
				$("<div class='carousel2-controls'>").append(buttondiv)
				);
			carousel2.element.append(
				$("<div class='carousel2-controlss'>").html(
					"<div class='carousel2-control  carousel2-control-left'><svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'><polyline points='71.85 12.58 48.15 60 71.85 107.42' style='stroke-linejoin:round;stroke-linecap:round;fill:none;stroke:#4C4F75;stroke-miterlimit:10;stroke-width:4px'/></svg></div>"+
					"<div class='carousel2-control carousel2-control-right'><svg id='Layer_2' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'><polyline points='48.15 12.58 71.85 60 48.15 107.42' style='stroke-linejoin:round;stroke-linecap:round;fill:none;stroke:#4C4F75;stroke-miterlimit:10;stroke-width:4px'/></svg></div>"
					)
				);
			};

		carousel2.init = function(){

			if(carousel2.element.data("timer")=="none") {
				carousel2.timerLength = 0;
			} else if(carousel2.element.data("timer")!=undefined) {
				carousel2.timerLength = +carousel2.element.data("timer")*1000;
			}
			carousel2.timerPause = carousel2.timerLength*2;

			carousel2.numberOfSlides = carousel2.element.find(".carousel2-slide").length;

			carousel2.element.find(".carousel2-slide").eq(0).addClass("atCenter");

			carousel2.makeButtons();

			carousel2.startTimer();
		};

		carousel2.element.on("click",".carousel2-control",function(){
			carousel2.changePosition($(this).is(".carousel2-control-right"));
			carousel2.pauseTimer();
		});
		carousel2.element.on("click",".carousel2-paginate", function() {
			carousel2.changePosition($(this).index());
			carousel2.pauseTimer();
		});

		carousel2.init();
	}
module.exports = Carousel2;