<div class="container ">
    <div class="flex-row-center-center bottom-padding-md">
        <div class="content-box flex-grow1_0"  >
            <h2 class="">Introducing Python to future generations.</h2>
            <p class="top-padding-xs">TI - Python adapter for TI-83 <br>Premium CE calculator</p>
        </div>
        <div class="top-image-box flex-grow1_0 " >
            <img class=" image-contain"    src="../../assets/images/tipython/ti83top.png" alt="ti calcuator 83">
        </div>
        
    </div>
</div>
<div class="neutral_light_bg top-padding-lg bottom-padding-lg">
    <div class="container">
        <div class="flex-row-between-start">
            <div class="top-image-box2 flex-grow2_0 " >
                <img class=" image-contain"    src="../../assets/images/tipython/ti83adapter.png" alt="ti calcuator 83">
            </div>
            <div class="content-box2 flex-grow1_0"  >
                <h4 class="" >Overview</h4>
                <P class="body_text_sm">TI - Python adapter is a solution that allows teachers and students to program in Python on the TI-83 Premium CE calculator. This project initially started as an external plugin accessory for the company’s best-seller product.</P>
                <h4 class="top-padding-md" >My Role</h4>
                <P class="body_text_sm">As part of the research team for the new product developments; I was given the task to identify key visual aspects that could elevate the coding learning experience for this product.</P>
            </div>
        </div>
    </div>
</div>

<div class="progress_bar_box mpadding">
    <h2 class="h2title stiky_nav_title top-padding-lg">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" >
        <a href="/tipython#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title">Product Goal Analysis</p>
            </div>
        </a>
        <a href="/tipython#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">Research</p>
            </div>
        </a>
        <a href="/tipython#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">New Proposal</p>
            </div>
        </a>
        <a href="/tipython#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Imlementation</p>
            </div>
        </a>
    </nav>
</div>

<div id="section1" class="container">
    <h1 class="bottom-padding-md top-padding-lg">Product Goal Analysis</h1>
    <p class="body_text_md bottom-padding-sm">As a completely new feature for this product,  it was very important to understand the following 3 parts of the product: the user needs, the company’s business goals, and the product goals with their constraints.  The process for this project started with putting together these 3  parts:</p>
    <div class="product-goals-box bottom-padding-md">
        <img class="image-contain"    src="../../assets/images/tipython/product-goals.png" alt="product goals">
    </div>
</div>
<div class="flex-col-center-center neutral_light_bg">
    <div class="container">
        <h2 class="text-center  top-padding-lg " >Design problem</h2>
        <p class="bottom-padding-lg"> “Transform the current Note editor into a Python script editor.“</p>
    </div>  
</div>
<div class="container">
    <p class="body_text_md bottom-padding-md top-padding-lg">Fortunately, we didn't have to start this product from scratch; the calculator already had a feature called ”Note editor” that we could be used as a starting point. </p>
    <div class="product-goals-box bottom-padding-lg">
        <img class="image-contain"    src="../../assets/images/tipython/ti83-transformation.png" alt="product goals">
    </div>
</div>
<div id="section2" class="container">
    <h1 class="bottom-padding-md ">User research</h1>
    <p class="body_text_md bottom-padding-sm">Looking for areas of opportunities to improve the user experience of the product, the team decided to create a high-fidelity mockup typing a short python script in the already existing “Note editor” feature. The purpose of this mockup was to get some feedback from python developers and perform a heuristic evaluation. Here are the top 5 user pain points that were identified during the user research. </p>
    <div class=" bottom-padding-sm">
        <img class="image-contain image-within-text" style="padding-right: 3em;"    src="../../assets/images/tipython/fist-mockup.png" alt="product goals">
        <ol class="pain-point-list">
            <li>The indentation is not clear.</li>
            <li>Lower case letters with descenders like “p” and “y” look uppercased.</li>
            <li>The overflow wrapped-words are hard to read.</li>
            <li>Scrolling down through all the code is cumbersome.</li>
            <li>The code is hard to understand 	when the text is all in one color.</li>
        </ol>
    </div>
    <p class="body_text_md bottom-padding-md">Since graphing calculators have a custom screen size; we looked for similar code editor apps for smartphones to analyze how they treat code strings on small-size screens. This gave us the idea of how to solve some of the user pain points and led us to the final design proposal.</p>
    <div class="product-goals-box bottom-padding-lg">
        <img class="image-contain"    src="../../assets/images/tipython/code-editors.png" alt="code editors">
    </div>
</div>
<div id="section3" class="container">
    <h1 class="bottom-padding-md ">New Proposal</h1>
    <div class="product-goals-box bottom-padding-md">
        <img class="image-contain"    src="../../assets/images/tipython/after_ti83.png" alt="proposal">
    </div>
</div>

<div class="container">
    <h2 class="bottom-padding-md ">Top 5 changes</h2>
    <div class="flex-row-start-center">
        <div class="number-circle flex-row-center-center"><p class="change-number">1</p></div>
        <p class="text-bold top-changes-title">Add an indicator for line wrapping</p>
    </div>
    <p class="change-description">Leave 5 blank spaces when the text is wrapped.</p>
    <div class="lef-row-center-center bottom-margin-sm">
        <img class="image-contain"    src="../../assets/images/tipython/change1.png" alt="proposal1"> 
    </div>
    <div class="flex-row-start-center">
        <div class="number-circle flex-row-center-center"><p class="change-number">2</p></div>
        <p class="text-bold top-changes-title">Improve indentation</p>
    </div>
    <p class="change-description">Use square symbol as an indentation indicator.</p>
    <div class="lef-row-center-center bottom-margin-md top-margin-md">
        <img class="image-contain"    src="../../assets/images/tipython/change2.png" alt="proposal2"> 
    </div>
    <div class="flex-row-start-center">
        <div class="number-circle flex-row-center-center"><p class="change-number">3</p></div>
        <p class="text-bold top-changes-title">Include bottom menu</p>
    </div>
    <p class="change-description">Reserve the last 20 pixels to display the bottom menu. </p>
    <div class="lef-row-center-center bottom-margin-md top-margin-md">
        <img class="image-contain"    src="../../assets/images/tipython/change3.png" alt="proposal3"> 
    </div>
    <div class="flex-row-start-center">
        <div class="number-circle flex-row-center-center"><p class="change-number">4</p></div>
        <p class="text-bold top-changes-title">Apply a new font size that supports descenders </p>
    </div>
    <p class="change-description">Help the user read more words horizontally by using the new CE Medium font size that supports up to 32 characters across the screen.</p>
    <div class="lef-row-center-center bottom-margin-md top-margin-md">
        <img class="image-contain"    src="../../assets/images/tipython/change4.png" alt="proposal4"> 
    </div>
    <p class="change-description">letters with descenders like “p”, “q”, “y”, “j” are now aligned correctly. Fixing this font anatomy would improve the legibility of the calculator. </p>
    <div class="lef-row-center-center bottom-margin-md top-margin-md">
        <img class="image-contain"    src="../../assets/images/tipython/change41.png" alt="proposal41"> 
    </div>
    <div class="flex-row-start-center">
        <div class="number-circle flex-row-center-center"><p class="change-number">5</p></div>
        <p class="text-bold top-changes-title">Use text color for reserved keywords</p>
    </div>
    <p class="change-description"> 
        It is easier for a Python learner to remember keywords when they are displayed with different colors. Use blue, red, green, and gray for the following categories</p>
    <div class="lef-row-center-center bottom-margin-lg top-margin-md">
        <img class="image-contain"    src="../../assets/images/tipython/change5.png" alt="proposal5"> 
    </div>
</div>
<div id="section4" class="neutral_light_bg top-padding-lg bottom-padding-lg">
    <div class="container">
        <h1 class="bottom-padding-md ">Implementation</h1>
        <div class=" flex-row-between-start">
            <p class="imp-text flex-grow1_0"> My biggest contribution to the implementation of the project was the creation of the NEW font.
                <br><br>
                The new font size improved the user experience while writing and reading code. 
            </p>
            <div class="top-image-box2 flex-grow1_0">
                <img class="image-contain"    src="../../assets/images/tipython/font-anatomy.png" alt="font anatomy">
            </div>
        </div>
        <div class="flex-row-center-center bottom-padding-md top-padding-md">
            <img class="image-contain new-font-image"    src="../../assets/images/tipython/new-font.png" alt="new font">
        </div>
    </div>
</div>
<div id="section5" class="final-result-box">
    <h1 class="result-title">Final Result</h1>
    <img class="image-contain "    src="../../assets/images/tipython/ti83-final.png" alt="result">
</div>
<div class="container">
    <h1 class="top-padding-lg">Other projects</h1>
    <div class="">
        <app-project-thumbs [excludedItems]=2></app-project-thumbs>
    </div>
</div>
