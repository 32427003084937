<div class="flex-row-start-start-nowrap top-padding-md bottom-margin-lg overscroll-x">
    <div *ngFor="let projectitem of projectitem | slice:0:4; let i=index" >
        <a class="other-projects flex-col-start-start" href="{{ projectitem.link }}">
            <div class="projetc-thumbnail" >
                <img class="image-contain thumbnail" src="../../assets/images/thumbnails/{{ projectitem.thumbnail }}.png" alt="nspire">
            </div>
            <p class="thumbnail-text">{{ projectitem.title }}</p>
        </a>
    </div>
</div>
