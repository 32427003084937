<div class="container">
    <h1 class="text-center top-padding-lg">Reducing building energy consumption.</h1>
    <p class="text-center">Building Energy Management System</p>
</div>
<div class=" top-margin-lg bottom-margin-lg">
    <img class=" image-contain"    src="../../assets/images/holidayinn/holidayinn-main.png" alt="enery management system">
</div>
<div class="neutral_light_bg top-padding-lg bottom-padding-md">
    <div class="container">
        <div class="flex-row-start-between">
            <div class="overview">
                <h4 class="" >Overview</h4>
                <p class="body_text_sm">A Building Energy Management System is a computer-based control system installed in buildings that control and monitor the building's mechanical and electrical equipment such as ventilation, lighting, power systems, fire systems, and security systems.</p>
            </div>
            <div class="myrole">
                <h4 class="" >My Role</h4>
                <p class="body_text_sm">Transform the default user interface into a friendlier system capable of communicating big amounts of data. Ideate and create  assets and widgets  for the development process.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="progress_bar_box mpadding">
    <h2 class="h2title stiky_nav_title top-padding-lg">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" >
        <a href="/holidayinn#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title">Heuristic Evaluation</p>
            </div>
        </a>
        <a href="/holidayinn#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">User Flows</p>
            </div>
        </a>
        <a href="/holidayinn#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">Brainstroming</p>
            </div>
        </a>
        <a href="/holidayinn#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Imlementation</p>
            </div>
        </a>
    </nav>
</div>
<div id="section1" class="container">
    <h1 class="bottom-padding-md top-padding-lg">The Heuristic Evaluation</h1>
    <p class="body_text_md bottom-padding-md">Building energy management systems are complex software that collects a large amount of data coming from different types of sensors. There are specialized products in this particular industry that help engineers to put together the entire system; most of them include a UI kit that not necessarily is user-friendly. Considering the short time I got for this project; I decided to apply a heuristic evaluation on previous projects that used these UI templates and see what could I do to improve the User experience of these types of systems. From the heuristic evaluation, I got the following top 5 design priorities to consider for this project.</p>
    
</div>
<div class="car container">
    <div class="carousel" data-timer="3"> <!-- change this value to control picture holding period -->

		<div class="carousel-deck">
			<div class="carousel-slide"></div>
			<div class="carousel-slide"></div>
			<div class="carousel-slide"></div>
			<div class="carousel-slide"></div>
            <div class="carousel-slide"></div>
            <div class="carousel-slide"></div>
            <div class="carousel-slide"></div>
            <div class="carousel-slide"></div>
            <div class="carousel-slide"></div>
            <div class="carousel-slide"></div>
		</div>
	</div>
</div>
<div class="container flex-col-center-end" >
    <a class="work_link" target="_blank" href="https://docs.google.com/presentation/d/121KxgTGWEuVGpqjfkBWNbsudzSY6Ta_Ruw56hQYtqpM/edit#slide=id.p" >See Full heuristic evaluation &#8594;</a>
</div>
<div class="container">
    <h1 class="bottom-padding-md top-padding-lg">The problem</h1>
    <div class="flex-col-center-center">
        <ul class="problem-list">
            <li class="body_text_md">The UI is difficult to navigate through and the data displayed on the screen isn't necessarily organized.</li>
            <li class="body_text_md">Final users express that working with these systems is very challenging and time-consuming.</li>
            <li class="body_text_md">The images that the templates use to represent equipment and floorplans are visually too far away from reality.</li>
        </ul>
    </div>
</div>
<div id="section2" class="container">
    <h1 class="bottom-padding-md top-padding-lg">User Flows</h1>
    <p class="body_text_md bottom-padding-sm">The next thing to do for this project was to define the information architecture and the user flow of the project. This task is usually taken during a meeting with engineers, project managers, and designers. We all take a look at the building layouts and equipment diagrams to organize all the information. During this meeting, we discuss UI ideas and make design decisions too.</p>
    <div class=" top-margin-md">
        <img class=" image-contain"    src="../../assets/images/holidayinn/walluserflow-flat.png" alt="enery management system">
    </div>
</div>

<div id="section3" class="container">
    <h1 class="bottom-padding-md top-padding-lg">Inspiration and brainstorming</h1>
    <p class="body_text_md bottom-padding-sm">At the beginning of each project, I like to take some time to look for visual inspiration and explore possible UI designs. Look at the following images that I used as inspiration for this project.</p>
    <div class=" top-margin-md">
        <img class=" image-contain"    src="../../assets/images/holidayinn/holidayinn-inspiration.png" alt="enery management system">
    </div>
</div>
<div id="section4" class="container">
    <h1 class="bottom-padding-md top-padding-lg">Implementation</h1>
    <p class="body_text_md bottom-padding-sm">Once I defined the color palette and visual design style, I proceed with designing the UI elements.</p>
</div>
<div  id="section5" class="top-padding-lg">
    <img class=" image-contain"    src="../../assets/images/holidayinn/holidayinn-ui-elements.png" alt="enery management system">
</div>
<div  class="neutral_light_bg top-padding-lg bottom-padding-lg">
    <div class="container">
        <h1 class="bottom-padding-md  text-center">The final result</h1>
        <div class="" fxLayout="column" fxLayoutAlign="center center">
            <div class="inner" >
                           
                <!-- COMPARISON SLIDER CODE START -->
                
                <div class="comparison-slider-wrapper">
                   <!-- Comparison Slider - this div contain the slider with the individual images captions -->
                    <div class="comparison-slider">
                       <div class="overlay">And I am the <strong>after</strong> image.</div>
                    <img class="" src="../../assets/images/holidayinn/holidayinn-before.png" alt="after">
                    <!-- Div containing the image layed out on top from the left -->
                    <div class="resize">
                        <div class="overlay">I am the <strong>before</strong> image.</div>
                       <img class="" src="../../assets/images/holidayinn/holidayinn-after.png" alt="before">
                    </div>
                    <!-- Divider where user will interact with the slider -->
                    <div class="divider"></div>
                   </div>
                   <!-- All global captions if exist can go on the div bellow -->
                   <h1 class="top-margin-xs text-center text-secondary" style="margin-left: 3px; margin-block-end: 0em;" > &#8593;</h1>
                   <p class="text-center" >Drag this line to the right and left to see the changes.</p>
                </div>
                
                <!-- COMPARISON SLIDER CODE END -->
            </div>
        </div>
    </div>
</div>
<div class="container">
    <h1 class="top-padding-lg">Other projects</h1>
    <div class="">
        <app-project-thumbs [excludedItems]=4></app-project-thumbs>
    </div>
</div>
