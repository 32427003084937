export const ProjectItems =[
    {
        "id":1,
        title:"Making math and science easier to learn.",
        thumbnail:"nspire-thumbnail",
        link:"/tinspire",
    },
    {
        "id":2,
        title:"Introducing Python to future generations.",
        thumbnail:"ti83-thumbnail",
        link:"/tipython",
    },
    {
        "id":3,
        title:"Redesigning the WIZ app to increase smart light users.",
        thumbnail:"wiz-thumbnail",
        link:"/wizapp",
    },
    {
        "id":4,
        title:"Reducing building enegy consumption.",
        thumbnail:"holidayinn-thumbnail",
        link:"/holidayinn",
    }
]