<div class="tin_top_image_container ">
    <img class="top_image" src="../../assets/images/tinspire/toptinspire.png" alt="calculators" >
</div>

<div class="flex-col-center-center neutral_light_bg">
    <div class="container">
        <h2 class="text-center slogan top-padding-lg bottom-padding-lg" >Making math and science easier to learn.</h2>
    </div>  
</div>
<div class="top-padding-lg bottom-padding-md">
    <div class="container">
        <div class="flex-row-start-between">
            <div class="overview">
                <h4 class="" >Overview</h4>
                <P class="body_text_sm">TI-nspire CX II is Textas Instruments' most sophisticated graphing calculator mainly used for educational purposes. The objective of this project was to increase the user experience of this product by unifying the UI design inconsistencies.</P>
            </div>
            <div class="myrole">
                <h4 class="" >My Role</h4>
                <P class="body_text_sm">For this project, I mostly worked on the creation of a product UI style guide that developers and project managers could use as a reference to build new features in the future. Evaluating the current UI heuristics and find a simple design solution to user pain points was also one of my commitments in this project. Here is a small section of what I did for this project.
                </P>
            </div>
        </div>
    </div>
</div>

<div class="progress_bar_box mpadding">
    <h2 class="h2title stiky_nav_title top-padding-md">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <a href="/tinspire#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title"> Research</p>
            </div>
        </a>
        <a href="/tinspire#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">Analysis</p>
            </div>
        </a>
        <a href="/tinspire#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">Ideation</p>
            </div>
        </a>
        <a href="/tinspire#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Discussion</p>
            </div>
        </a>
        <a href="/tinspire#section5" class="sticky_nav_item sticky-five" [class.sticky_active]="selectedLink === 'five'" (click)="markSelected('five')"  target="_self">
            <div class="sticky_content">
                <div class="circle">5</div>
                <p class="progress_title">Develop</p>
            </div>
        </a>
        
    </nav>
</div>

<div id="section1" class="container">
    <h1 class="bottom-padding-md top-padding-md">Research</h1>
    <h3 class="bottom-padding-sm">Personas</h3>
    <p class="body_text_md bottom-padding-sm">The design process for this project started with the creation of two “personas”: Duncan and Carlos. Duncan represents the teachers with previous knowledge in math and science. He first receives training on how to use a graphing calculator, and post this he integrates activities with the calculator into his classroom. Carlos on the other hand represents the students who attend school to learn math and science for the first time. </p>
    <p class="body_text_md bottom-padding-md">The most interesting thing I learned about these personas is that students and teachers are DEPENDANT from each other. This graphing calculator is a complicated device that needs proper guidance on how to use it.</p>
    <div class="ti_personas_box">
        <div class="personas_container">      
            <img class="image-contain" src="../../assets/images/tinspire/ti_personas.png" alt="Ti personas" >
        </div> 
        <div class="display-flex">
            <p class="text_body_sm">Students</p>
            <div class="flex-stretch"></div>   
            <p class="text_body_sm">Teachers</p>
        </div>
    </div>
    <h2 class="top-padding-md bottom-padding-sm">User Pain Points</h2>
    <p class="body_text_md bottom-padding-md">Hearing stories from users, teammates, and project managers I identified the following user pain points:
    </p>
    <div class="flex-row-between-center">
        <div class="illustration">
            <img class="image-contain" src="../../assets/images/tinspire/handheald-illustration.png" alt="experience icon" >
        </div>
        <!--CALLOUT BOX sourse https://mrcoles.com/blog/callout-box-css-border-triangles-cross-browser/#css-triangles-code -->
        <div class="experiences_list">
            <div class="callout">
                <div class="flex-row-between-center">
                    <p class="callout-text" >The blue circle I drew during the last class disappeared.</p>
                    <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                </div>
                <b class="notch"></b>
            </div>
            <div class="callout">
                <div class="flex-row-between-center">
                    <p class="callout-text" >I don’t see the y-axis and x-axis on the screen.</p>
                    <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                </div>
                <b class="notch"></b>
            </div>
            <div class="callout">
                <div class="flex-row-between-center">
                    <p class="callout-text" >I tap Shift + “mode” but nothing happens.</p>
                    <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                </div>
                <b class="notch"></b>
            </div>
            <div class="callout">
                <div class="flex-row-between-center">
                    <p class="callout-text" >Can’t find the option “shapes” in the menu.</p>
                    <div class="experience-icon"><img class="image-contain" src="../../assets/images/tinspire/experience_icon_bad.png" alt="experience icon" ></div>
                </div>
                <b class="notch"></b>
            </div>
        </div>
    </div>
    <p class="body_text_md top-padding-md bottom-padding-lg">All these pain points had one thing in common: the users were all trying to do a task in the wrong application. After the user research, the main design problem was described as the following statement.</p>
</div>
<div class="flex-col-center-center neutral_light_bg">
    <div class="container">
        <h2 class="text-center top-padding-lg " >Design problem</h2>
        <p class="bottom-padding-lg">“The user is not able to identify the currently open app.”</p>
    </div>  
</div>
<div id="section2" class="container">
    <h1 class="bottom-padding-md top-padding-lg">Analysis</h1>
    <p class="body_text_md bottom-padding-md">Looking for the answer to why and how the user was not able to identify the currently open app, I decided to check the user flow shown below. After looking at the user flow diagram, the reason why the user confuses the apps was clear. Different apps screens look 95% similar to each other.</p>
    <h3>— User Flow</h3>
    <img class="image-contain bottom-margin-md" src="../../assets/images/tinspire/ti-userflow.png" alt="ti user flow" >

    <h3>Why choose to fix this?</h3>
    <p class="top-padding-md bottom-padding-sm">This problem was mostly happening between Calculator, Graph, Geometry, and Notes applications which means that <span style="font-weight: 800;">4 out of 7 apps in this calculator have this problem.</span></p>
    <p class="bottom-padding-lg">New users and non-expert users were more likely to experience these pain points. That drove me to the conclusion that there were big changes to <span style="font-weight: 800;">reduce the user learning curve</span> of the company’s most sophisticated graphing calculator.</p>
</div>
<div id="section3" class="container">
    <h1 class="bottom-padding-md">Ideation</h1>
    <p class="body_text_md bottom-padding-md">During the ideation process, I usually do brainstorming to explore different design solutions. Here are some ideas I had for this particular project.</p>
    <div class="flex-row-start-start">
        <div class="picker simple">
            <div class="main-image">
                <img src="../../assets/images/tinspire/ti-idea1.png" alt="idea image">
            </div>
            <div class="thumbstrip">
                <img src="../../assets/images/tinspire/ti-idea1.png" alt="idea 1">
                <img src="../../assets/images/tinspire/ti-idea2.png" alt="idea 2">
                <img src="../../assets/images/tinspire/ti-idea3.png" alt="idea 3">
                
            </div>
        </div>
        <div class="idea_list">
            <p class="text-bold idea-title">Idea 1:</p>
            <p class="">Use the app icon in the header</p>
            <br>
            <p class="text-bold idea-title">Idea 2:</p>
            <p class="">Use a dynamic navbar color</p>
            <br>
            <p class="text-bold idea-title">Idea 3:</p>
            <p class="">Use a dynamic outline color</p>
        </div>
    </div>
</div>
<div class="container">
    <p class="top-padding-md">After a discussion with the team, we eliminated idea number one and decided to use color to create an association between the app and the screen.
    </p>
    <h1 class="bottom-padding-md top-padding-md">Mockups</h1>
    <p class="body_text_md bottom-padding-md">There were mainly two potential ideas that fit with the statement above. I created two high-fidelity mockups to show the team how they would look. Additionally, I made an accessibility test over option A (see image below) and a comparative table describing the pros & cons of each design.</p>
    <div class="flex-row-start-center">
        <div class="data_icon">
            <img class="image-contain" src="../../assets/images/tinspire/ti-data-icon.png" alt="icon">
        </div>
        <p class="text-bold data-icon-text">Data & Statistics: #C14E9D</p>
    </div>
    <div class="flex-row-center-center top-margin-md bottom-margin-lg">
        <div class="flex-col-center-center">
            <p class="text-bold">Option A</p>
            <div class="ti-mockup">
                <img class="image-contain" src="../../assets/images/tinspire/ti-mockup-a.png" alt="option A">
            </div>
        </div>
        <div class="flex-col-center-center">
            <p class="text-bold">Option B</p>
            <div class="ti-mockup">
                <img class="image-contain" src="../../assets/images/tinspire/ti-mockup-b.png" alt="option B">
            </div>
        </div>
    </div>
</div>
<div class="neutral_light_bg">
    <div id="section4" class="container">
        <h1 class="bottom-padding-md top-padding-lg">Discussion</h1>
        <p class="body_text_md bottom-padding-sm">The top 2 ideas were discussed with the product management team.</p>
        <div class="ti-discussion-image">
            <img class="image-contain" src="../../assets/images/tinspire/ti-discussion.png" alt="discussion images">
        </div>
        <p class="body_text_md bottom-padding-lg top-padding-sm">Unfortunately, the color application on option A failed the accessibility test and finally, <span style="font-weight: 800;">option B was chosen as the final design solution.  </span></p>
    </div>
</div>
<div id="section5" class="container">
    <h1 class="bottom-padding-md top-padding-lg">Develop</h1>
    <p class="body_text_md bottom-padding-md">The creation of new assets was not required for this project but I kept in contact with the developers’ team during the execution of the project to answer any questions and specs updates.  </p>
    <h3 class="bottom-padding-sm">Specs</h3>
    <div class="ti-spec-image">
        <img class="image-contain" src="../../assets/images/tinspire/ti-specs1.png" alt="specs part 1">
    </div>
    <p class="body_text_md bottom-padding-md top-padding-md">Additional specs for split screens were given:</p>
    <div class="ti-spec-image">
        <img class="image-contain" src="../../assets/images/tinspire/ti-specs2.png" alt="specs part 1">
    </div>  
</div>

<div id="section6" class="container">
    <h1 class="bottom-padding-md top-padding-md">Final Result</h1>
</div>
<div class="flex-row-center-start bottom-padding-lg">
    <div class="result">
        <img class="image-contain" src="../../assets/images/tinspire/ti-result.png" alt="discussion images">
    </div>
    <div class="result-gif">
        <img class="image-contain" src="../../assets/images/tinspire/final-result.gif" alt="discussion images">
    </div>
</div>
<div class="container">
    <h1 class="top-padding-sm">Other projects</h1>
    <div class="">
        <app-project-thumbs [excludedItems]=1></app-project-thumbs>
    </div>
</div>