<div class="logo">
    <a class="branding" routerLink="/" >
        <img class=" image-contain"    src="../../assets/images/navbar/klogo.png" alt="K logo">
    </a>
</div>

<div class="clov-work-intro position-rel">
    <div class="position-rel">

        <div class="clov-intro-l">
            <div class="clov-intro-titles-box flex-row-center-start-nowrap">
                <h1 class="clov-intro-title-text">Clover  bakery  </h1>
            </div>
            <div class="clov-home-top-horizonal-line"></div>
            <p class="clov-intro-subtitle-text text-highlight">Order your bread fresh out of the oven</p>
        </div>
        <div class="clov-intro-r ">
            <div class="clov-hero-container">        
                <img  class="image-contain clov-hero-img" src="../../assets/images/clover/clov-intro-hero.png">
            </div>
        </div>
    </div>
    <div class="clov-intro-life-img-box"></div>
</div>

<!-- Role -->
<div class="white-bg">
    <div class="container">
        <div class="grid role-spaces">
            <div class="role-l col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="separator-role"></div>  
                    <div class="role-text-box ">
                        <h4 class="" >Overview</h4>
                        <p class="body_text_sm bottom-padding-md">Black clover is a cafe and bakery located in San Jose, California. Their Japanese-style bread has been famous in the area for over 20 years. However, the small business has not had a branding upgrade since its opening. </p>
                    </div>
                </div>
            </div>
            <div class="role-r col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="role-text-box">
                        <h4 class="" >My Role</h4>
                        <p class="body_text_sm">My role in this project was to upgrade their website and develop a new cake-ordering system allowing users to customize their cake for a particular date. Today's existing experience is in person, and customers need to understand the available options with personal assistance.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Sticky progress bar with jump links and shrink effect on scroll  

tutorial links: 
        sticky bar      ------------           
        https://www.w3schools.com/howto/howto_js_navbar_sticky.asp
         https://therichpost.com/angular-8-sticky-navbar-working-example/
        shrink a header in scroll   -------     https://www.w3schools.com/howto/howto_js_shrink_header_scroll.asp
        jumping links         ---------         https://www.bennadel.com/blog/3402-creating-a-jump-to-anchor-fragment-polyfill-in-angular-5-2-0.htm   

-->
<div class="progress_bar_box">
    <h2 class="h2title stiky_nav_title top-padding-md">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <a href="#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title">Logo</p>
            </div>
        </a>
        <a href="#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">Website</p>
            </div>
        </a>
        <a href="#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">App</p>
            </div>
        </a>
        <!-- <a href="/compostino#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Branding</p>
            </div>
        </a> -->
    </nav>
</div>

<!-- Section 1 -->
<div class="white-bg" id="section1">
    <div class="container">
        <div class="grid">
            <div class="clov-section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num1">1</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">Designing the</p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Logo</h2>
                    </div>
                </div>
            </div>
            <div class="clov-section-numb-1-r top-margin-lg">
                <div class="image-tag-wrap">
                    <div class="separator-img-tag"></div>  
                    <p class="img-tag-text">Design variations</p>
                </div>
                <div class="">
                    <img  class="image-contain " src="../../assets/images/clover/clov-logo-sketch.png">
                </div>
                <h4 class="top-padding-sm">Initial drawings</h4>
                <p class="top-padding-sm">I started this project by sketching on paper while analyzing the owner's styling preferences, target audience, and brand principles. These are the eight options I decided to take to the digital phase, and later on, narrow it down to 3 options. </p>
            </div>
        </div>
    </div>
</div>
<div class="white-bg">
    <div class="container">
        <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
            <div class="carousel" data-timer="3"> <!-- change this value to control picture holding period -->
                <div class="carousel-deck">
                    <div class="carousel-slide"></div>
                    <div class="carousel-slide"></div>
                    <div class="carousel-slide"></div>
                    <div class="carousel-slide"></div>
                </div>
            </div>
            <p class="top-padding-sm bottom-padding-md p-0-margin">After choosing the final version, I applied the logo on some merchandise items, such as cups, paper bags, and business cards. </p>
        </div>
    </div>
</div>

<div class="color-bg">
    <div class="">
        <img  class="image-contain " src="../../assets/images/clover/clov-merchandise.png">
    </div>
</div>

<!-- section2 -->
<div id="section2" class="white-bg bottom-padding-md top-padding-md">
    <div class="container">
        <div class="clov-section-numb-3-l ">
            <h1 class="clov-num3">2</h1>
            <div class="">
                <div class="clov-nub3-titles-box">
                    <p class="clov-num3-subtitle text-highlight ">Updating the website with a</p>
                    <div class="clov-num3-horizonal-line"></div>
                    <h2 class="clov-num3-title">New look</h2>
                </div>
            </div>
        </div>
        <div class="clov-section-numb-3-r ">
            <p>The rebranding project allowed us to revise the user flow and information architecture of the website. There was a need for more valuable and attractive content to increase the number of visitors. The UI also needed improvements in the visual hierarchy.</p>
            <div class="image-tag-wrap">
                <div class="separator-img-tag"></div>  
                <p class="img-tag-text">Site map</p>
            </div>
            <div class="">
                <img  class="image-contain " src="../../assets/images/clover/clov-sitemap.png">
            </div>
        </div>  
        <div class="top-margin-md" fxLayout="column" fxLayoutAlign="center center">
            <div class="inner" >
                           
                <!-- COMPARISON SLIDER CODE START -->
                
                <div class="comparison-slider-wrapper">
                   <!-- Comparison Slider - this div contain the slider with the individual images captions -->
                    <div class="comparison-slider">
                       <div class="overlay">And I am the <strong>after</strong> image.</div>
                    <img class="" src="../../assets/images/clover/clov-after.png" alt="after">
                    <!-- Div containing the image layed out on top from the left -->
                    <div class="resize">
                        <div class="overlay">I am the <strong>before</strong> image.</div>
                       <img class="" src="../../assets/images/clover/clov-before.png" alt="before">
                    </div>
                    <!-- Divider where user will interact with the slider -->
                    <div class="divider"></div>
                   </div>
                   <!-- All global captions if exist can go on the div bellow -->
                   <h1 class="top-margin-xs text-center text-secondary" style="margin-left: 3px; margin-block-end: 0em;" > &#8593;</h1>
                   <p class="text-center" >Drag this line to the right and left</p>
                </div>
                
                <!-- COMPARISON SLIDER CODE END -->
                <!-- sourse from https://codepen.io/karinmini/pen/wvXdRPK -->

            </div>
        </div>
    </div>
    <div class="clov-web-highfidelity-img">
        <img  class="image-contain " src="../../assets/images/clover/clov-web-highfidelity.png">
    </div>
</div>
<div class="clov-life-img-box"></div>

<!-- Section 3 -->
<div class="white-bg" id="section3">
    <div class="container">
        <div class="grid">
            <div class="clov-section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num1">3</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">Clover Bakery cake </p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Ordering app</h2>
                    </div>
                </div>
            </div>
            <div class="clov-section-numb-1-r top-margin-md">
                <div class="grid">
                    <div class="problem-statement-box col-sm-12 bottom-margin-lg top-margin-lg">
                        <h4 class="text-highlight">Problem Statement</h4>
                        <div class="statement-wrap">
                            <div class="quote-l">
                                <img  class="quote-l-img " src="../../assets/images/wizapp/top-quote.png">
                            </div>
                            <div>
                                <p class="statement-text text-highlight">How might we clarify the pricing system while customizing a cake? </p>
                            </div>
                            <div class="quote-r">
                                <img  class="quote-r-img " src="../../assets/images/wizapp/bottom-quote.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p>For this project, I focused on the following user flow: </p>
        <div class="image-tag-wrap  top-padding-sm">
            <div class="separator-img-tag"></div>  
            <p class="img-tag-text">User flow 1</p>
        </div>
        <div class="top-margin-md">
            <img  class="image-contain " src="../../assets/images/clover/clov-userflow1.png">
        </div>
    </div>
</div>
<!-- ARCHITECTURE -->
<div  class="white-bg top-padding-lg bottom-padding-lg">
    <div class="grid gap">
        <div class="section-arch-r col-sm-12 col-lg-6">
            <div class="arch-title-container">
                <!-- <h1 class="arch">3</h1> -->
                <div class="arch-titles-box">
                    <p class="arch-subtitle text-highlight ">Information</p>
                    <div class="arch-horizonal-line"></div>
                    <h2 class="arch-title">Architecture</h2>
                </div>
            </div>
        </div>
        <div class="clov-section-arch-l col-sm-12 col-lg-6 ">
            <p class="top-padding-sm">Analyzing the information a user managed during the in-person experience was crucial and key to the success of this project. Here are the main insights from the analysis:</p>
            <p class="top-padding-sm"><span class="text-bold">Key insight 1:</span>The cake price changes according to size, flavor, and toppings. </p>
            <p class="top-padding-sm"><span class="text-bold">Key insight 2:</span> Additional toppings are allowed in this bakery.</p>
            <p class="top-padding-sm"><span class="text-bold">Key insight 3:</span>The cake's message is placed in a note. </p>
        </div>
    </div>
</div>
<!-- prototype -->
<div class="prototype-wrapper">
    <div class="protoype-bg-box">
            <img  class="image-contain" src="../../assets/images/clover/proto-bg.png">
    </div>
    <div class="proto-content-box ">
       <h3 class="bottom-padding-sm">Cake ordering system proposal</h3>
       <div class="prototype-bg">

        <img  class=" prototype-gif" src="../../assets/images/clover/clover-app-prototype.gif">
        <img  class="image-contain  ipad-frame" src="../../assets/images/clover/ipad-frame.png">

       </div>
    </div>
</div>
<div class="container">
    <h3 class="text-highlight top-padding-md ">Iconography</h3>
    <div class=" flex-row-start-between gap bottom-padding-md">
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-vanilla.png">
                <p class="text-center clov-icon-text">Vanilla</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-green-tea.png">
                <p class="text-center clov-icon-text">Green tea</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-coffee.png">
                <p class="text-center clov-icon-text">Coffee</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-chocolate.png">
                <p class="text-center clov-icon-text">Chocolate</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-strawberry1.png">
                <p class="text-center clov-icon-text">strawberry1</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-strawberry2.png">
                <p class="text-center clov-icon-text">strawberry2</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-email.png">
                <p class="text-center clov-icon-text">Menu</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-blueberry.png">
                <p class="text-center clov-icon-text">Blueberry</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-search.png">
                <p class="text-center clov-icon-text">Search</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-like.png">
                <p class="text-center clov-icon-text">Like</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-instagram.png">
                <p class="text-center clov-icon-text">Instagram</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-profile.png">
                <p class="text-center clov-icon-text">Profile</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-twitter.png">
                <p class="text-center clov-icon-text">Twitter</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-facebook.png">
                <p class="text-center clov-icon-text">Facebook</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-tool1.png">
                <p class="text-center clov-icon-text">Tool1</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-tool2.png">
                <p class="text-center clov-icon-text">Tool2</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-tool3.png">
                <p class="text-center clov-icon-text">Tool3</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-onigiri.png">
                <p class="text-center clov-icon-text">Onigiri</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-sandwich.png">
                <p class="text-center clov-icon-text">Sandwich</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-donut.png">
                <p class="text-center clov-icon-text">Donut</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-bread.png">
                <p class="text-center clov-icon-text">Bread</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-email.png">
                <p class="text-center clov-icon-text">Email</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-phone.png">
                <p class="text-center clov-icon-text">Phone</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-setting.png">
                <p class="text-center clov-icon-text">Settings</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-cake.png">
                <p class="text-center clov-icon-text">Cake</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-save.png">
                <p class="text-center clov-icon-text">Save</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-vegan.png">
                <p class="text-center clov-icon-text">Vegan</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-eggfree.png">
                <p class="text-center clov-icon-text">Egg-free</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-dairyfree.png">
                <p class="text-center clov-icon-text">Dairy-free</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-coffee.png">
                <p class="text-center clov-icon-text">Gluten-free</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-cornfree.png">
                <p class="text-center clov-icon-text">Corn-free</p>
            </div>
        </div>
        <div class="flex-row-center-center-nowrap flex-grow1_0 clov-icon-box">
            <div class="clov-icon">
                <img  class="image-contain" src="../../assets/images/clover/icons/icon-peanutfree.png">
                <p class="text-center clov-icon-text">Peanut-free</p>
            </div>
        </div>
        
    </div>
    <h3 class="text-highlight top-padding-md ">Final result</h3>


</div>

<div id="section4" class="clov-final-secttion ">
        <img  class="image-contain " src="../../assets/images/clover/clov-app-highfidelity-screens.png"> 
</div>
<!-- foolter -->
<div class="clov-footer-bg ">
    <div class="container flex-row-between-center colv-footer-box">
        <div class="social-media flex-row-center-center bottom-padding-md">
            <p class="myname-footer">Kimi Kurata</p>
            <hr class="footer-separator-a"/>
            <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
            <hr class="footer-separator-b"/>
            <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
        </div>
        <div class="clov-footer-vl flex-row-start-end">
            <div class="vl-gradient clov-vlfooter"></div>
            <p class="body_text_xsm veritical-text bottom-padding-md clov-copyright">&copy;copyright 2022 </p>
        </div>
    </div>
</div>








<!-- 



<div  class="white-bg">
    <div class="container top-padding-md bottom-padding-md white-bg">
        <h3 class="bottom-padding-md text-highlight">Market statistics</h3>
        <div class="grid gap">
            <div class="comp-market-l col-sm-12 col-lg-6">
                <img  class="image-contain " src="../../assets/images/compostino/comp-piechart.png">
            </div>
            <div class="comp-market-r col-sm-12 col-lg-6 red">
                <div class="pie-chart-text-box ">
                    <div class="comp-separator-market "></div> 
                    <div class="market-text-container">
                        <h4 class="market-title">TOP 4 screen time activities:</h4>
                        <p>53% TV/Videos</p>
                        <p>31% Gaming</p>
                        <p>05% Browsing websites</p>
                    </div>
                </div>
            </div>
            <div class="market-card col-sm-12 col-lg-4 ">
                <p class="market-title">  More Than</p>
                <h1 class="market-num-a market-doble-outline-a">50%</h1>
                <p class="text-center">of all kids aged 6-11 use their own tablet at home.</p>
            </div>
            <div class="market-card col-sm-12 col-lg-4 ">
                <p class="market-title"> Kids from</p>
                <div class="flex-row-center-center-nowrap" > 
                    <h1 class="market-num-b market-doble-outline-b">9-11</h1>
                    <p class="text-bold market-unit-b">y.o.</p>
                </div>
                <p class="text-center">are more likely to use a tablet provided by the school.</p>
            </div>

            <div class="market-card-c col-sm-12 col-lg-4 ">
                <p class="market-title"> Kids spend</p>
                <div class="flex-row-center-center-nowrap" > 
                    <h1 class="market-num-c market-doble-outline-c">5</h1>
                    <p class="text-bold market-unit-c">h</p>
                </div>
                <p class="text-center">a day in front of digitals screens.</p>
            </div>
            
        </div>

        <div>
            <a target="_blank" href="https://www.commonsensemedia.org/sites/default/files/research/report/2019-census-8-to-18-full-report-updated.pdf"><p class=" source-text">019-census-8-to-18-full-report</p></a>
        </div>
    </div>    
</div> -->
<!-- Problem statement  -->
<!-- <div class="color-bg">
    <div class="container">
        <div class="grid">
            <div class="problem-statement-box col-sm-12 bottom-margin-lg top-margin-lg">
                <h4 class="text-highlight">Problem Statement</h4>
                <div class="statement-wrap">
                    <div class="quote-l">
                        <img  class="quote-l-img " src="../../assets/images/wizapp/top-quote.png">
                    </div>
                    <div>
                        <p class="statement-text text-highlight">How might we use digital devices to keep kids interested during the long process of turning food waste into compost?</p>
                    </div>
                    <div class="quote-r">
                        <img  class="quote-r-img " src="../../assets/images/wizapp/bottom-quote.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Subtitle 2-->
<!-- <div id="section2" class="comp-section2-bg">
    <div class="container">
        <div class="grid gap">
            <div class="comp-section-numb-2-r col-sm-12 col-lg-10 top-margin-lg">
                <h4 class="bottom-padding-sm">The moodboad</h4>
                <div class="moodboard" >
                    <img  class="image-contain " src="../../assets/images/compostino/comp-moodboard.png">
                </div>
                <h4 class="top-padding-md bottom-padding-sm">The word list </h4>
                <div class="moodboard" >
                    <img  class="image-contain " src="../../assets/images/compostino/comp-wordlist.png">
                </div>
                <h4 class="top-padding-md  bottom-padding-sm">Preliminary user flow</h4>
                <div class="moodboard" >
                    <img  class="image-contain " src="../../assets/images/compostino/comp-userflow.png">
                </div>
            </div>
            <div class="comp-section-numb-2-l col-sm-12 col-lg-2">
                <h1 class="comp-num2">2</h1>
                <div class="position-rel">
                    <div class="comp-nub2-titles-box">
                        <p class="comp-num2-subtitle text-highlight ">Defining task flows &</p>
                        <div class="comp-num2-horizonal-line"></div>
                        <h2 class="comp-num2-title">Experiences</h2>
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</div> -->

<!-- 
<div class="white-bg">
    <div class="container">
        <h4 class="bottom-padding-sm">Iconography Explorations</h4>
        <p>During the design phase of this project, I created a set of outlined icons hoping to use them in the UI, as shown below. However, these icons weren't working as I imagined; I thought they needed to match the logo style better, and  I could give the screen's main buttons more visual weight with a filled icon style. </p>
        <p>I took my design options to a design critique session with my peer designers to gather feedback and validate my theory. </p>
        <div class="grid gap top-margin-md bottom-padding-md">
            <div class=" col-lg-5 col-sm-5 " >
                <div class="outline">
                    <img  class="image-contain " src="../../assets/images/compostino/comp-outline-icons.png">
                </div>
            </div>
            <div class=" col-lg-2">
                <h1 class="vs-text vs-doble-outline">VS</h1>

            </div>
            <div class=" col-lg-5 col-sm-5 ">
                <div class="fill">
                    <img  class="image-contain " src="../../assets/images/compostino/comp-fill-icons.png">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- app section -->










<!-- Section 4 -->
<!-- <div class="white-bg" id="section4">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num4">4</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">UI  gudelines &</p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Brand Identity</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
                <div class="carousel2" data-timer="3"> 
                    <div class="carousel2-deck">
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                        <div class="carousel2-slide"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="white-bg"> -->
    <!-- <div id="section5" class="final-secttion ">

        
    </div> -->


<!-- </div> -->





