<div class="about-bg-color">
    <div class="about-bg">
        <div class="container">
            <!-- sourse  https://codepen.io/hi-im-si/pen/DHoup -->
            <div class="introduction-animation-box flex-row-start-end">
                <h1 class="text-highlight greeting">Hi</h1>
                <h1 class="coma doble-outline">,</h1>
                <h1 class="right20">I'm &nbsp;</h1>
                <h1 class="right20">
                    <a href="" class="typewrite" data-period="2000" data-type='[ " a self-starter.", "a good listener.", "a innovator." ]'>
                    <span class="wrap"></span>
                    </a>
                </h1>
            </div>
        </div>
        <div class="about-top-horizonal-line"></div>
        <div class="container">    
            <div class="quote-about flex-row-center-center-nowrap top-margin-lg bottom-margin-lg">
                <div class="vl vl-about0"></div>
                <p class="quote-p">Communication between designers and developers shouldn’t be an obstacle to creating an outstanding outcome. I’m a UI UX designer who understands both; design and engineering. </p>
            </div>
        </div>       
        <div class="about-text-box">
            <div class="container top-padding-md bottom-padding-md">
                <div class="flex-row grid">
                    <div class="col-md-10 col-sm-12 col-xs-12 ">
                        <h3 class="text-highlight bottom-padding-sm">Experience</h3>
                        <div class="">
                            <div class="flex-row-start-start-nowrap">
                                <h4 class="education_title">Barracuda Networks</h4>
                                <!-- <p class="timeline_text">&nbsp;| Sr.UI/UX Designer</p> -->
                            </div>
                            
                            <div class="flex-row-between-center"> 
                                <p class="timeline_text">Sr.UI/UX Designer | Cybersecurity</p>
                                <p class="timeline_text">Jan 2022 - Current</p>
                            </div> 
                        </div>
                        <div class="top-margin-md bottom-margin-md">
                            <div class="flex-row-start-start-nowrap">
                                <h4 class="education_title">Dextra Technologies / Texas Instruments</h4>
                                <!-- <p class="timeline_text">&nbsp;| UI/UX Designer</p> -->
                            </div>
                            <div class="flex-row-between-center"> 
                                <p class="timeline_text">UI/UX Designer | Education Technology Graphing Calculators</p>
                                <p class="timeline_text">Sep 2017 - Jan 2019</p>
                            </div> 
                        </div>
                        <div>
                            <div class="flex-row-start-start-nowrap">
                                <h4 class="education_title">Emba group</h4>
                                <!-- <p class="timeline_text">&nbsp;| UI/UX Designer</p> -->
                            </div>
                            <div class="flex-row-between-center"> 
                                <p class="timeline_text">UI/UX Designer | Energy Management System for Smart Buildings</p>
                                <p class="timeline_text">Sep 2015 - Sep 2017</p>
                            </div> 
                        </div>              
                    </div>
                </div>
            </div>

        </div>
        <div class="container top-padding-md bottom-padding-md">
            <div class="flex-row grid">
                <div class="col-md-10 col-sm-12 col-xs-12 ">
                    <h3 class="text-highlight bottom-padding-sm">Education</h3>
                    <div class="">
                        <h4 class="education_title">M.A in Interaction and UX Design</h4>
                        <hr class="education-divider">
                        <div class="flex-row-between-center"> 
                            <p class="timeline_text">Academy of Art University</p>
                            <p class="timeline_text">Dic 2022</p>
                        </div> 
                    </div>
                    <div class="top-margin-md bottom-margin-md">
                        <h4 class="education_title">M.A in Engineering</h4>
                        <hr class="education-divider">
                        <div class="flex-row-between-center"> 
                            <p class="timeline_text">Panamerican University</p>
                            <p class="timeline_text">Jul 2020</p>
                        </div> 
                    </div>
                    <div class="bottom-margin-lg">
                        <h4 class="education_title">B.A in Innovation & Design Engineering</h4>
                        <hr class="education-divider">
                        <div class="flex-row-between-center"> 
                            <p class="timeline_text">Panamerican University</p>
                            <p class="timeline_text">Jul 2015</p>
                        </div> 
                    </div>
                    <h3 class="text-highlight bottom-padding-sm">Skills</h3>
                    <div class="flex-row-start-between ">
                        <div class="flex-grow1_0">
                            <p class="timeline_text">Figma</p>
                            <p class="timeline_text">Helio</p>
                            <p class="timeline_text">Mixpanel</p>
                        </div>
                        <div class="flex-grow1_0">
                            <p class="timeline_text">User testing</p>
                            <p class="timeline_text">Prototyping</p>
                            <p class="timeline_text">UI style guides</p>
                        </div>
                        <div class="flex-grow1_0">
                            <p class="timeline_text">HTML5</p>
                            <p class="timeline_text">CSS</p>
                            <p class="timeline_text">Angular</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <h4 class="education_title">Recent readings</h4>
        </div>
        <div class="readings-horizonal-line"></div>
        <div class="container">
            <div class="flex-row-between-center top-margin-md ">
                <div class="flex-col-start-center">
                    <img class="image-contain img200x200" src="../../assets/images/about/interviewing users.jpeg"  alt="book">
                    <h5>Interviewing Users</h5>
                </div>
                <div class="flex-col-start-center">
                    <img class="image-contain img200x200" src="../../assets/images/about/articulation design.jpeg" alt="book">
                    <h5>Articulating Desi..</h5>
                </div>
                <div class="flex-col-start-center">
                    <img class="image-contain img200x200" src="../../assets/images/about/little_black_book.jpeg" alt="book">
                    <h5>Little Black Book</h5>
                </div>
			</div>
        </div>
    </div>
</div>
<div class="footer-bg">
    <div class="container flex-row-between-end ">
        <div class="social-media flex-row-center-center bottom-padding-md">
            <p class="myname-footer">Kimi Kurata</p>
            <hr class="footer-separator-a"/>
            <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
            <hr class="footer-separator-b"/>
            <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
        </div>
        <div class="footer-vl flex-row-start-end">
            <div class="vl-gradient vlfooter"></div>
            <p class="body_text_xsm veritical-text bottom-padding-md copyright">&copy;copyright 2022 </p>
        </div>
    </div>
</div>