<div class="logo">
    <a class="branding" routerLink="/" >
        <img class=" image-contain"    src="../../assets/images/navbar/klogo.png" alt="K logo">
    </a>
</div>
<div class="work-intro position-rel">
    <div class="grid">
        <div class="wiz-intro-l col-sm-12 col-lg-6">
            <div class="intro-titles-box flex-row-center-center-nowrap">
                <h1 class="intro-title-text">WIZ</h1>
                <div class="position-rel">
                    <h1 class="outline-highlight text-highligh ">/</h1>
                    <h1 class="outline-base  text-highligh">/</h1>
                </div>
                <h1 class="intro-title-text">APP</h1>
            </div>
            <div class="home-top-horizonal-line"></div>
            <p class="intro-subtitle-text text-highlight">Smart lights for your daily living</p>
        </div>
        <div class="wiz-intro-r col-sm-12 col-lg-6">
            <div class="">        
                <img  class="image-contain " src="../../assets/images/wizapp/wiz-intro-hero.png">
            </div>
        </div>

    </div>
    <div class="wiz-intro-life-img-box"></div>
</div>
<div class="white-bg">
    <div class="container">
        <div class="grid">
            <div class="role-l col-sm-12 col-lg-6">
                <div class="wiz-role-img">
                    <img  class="image-contain " src="../../assets/images/wizapp/wiz-role-hero.png">
                </div>
            </div>
            <div class="role-r col-sm-12 col-lg-6">
                <div class="role-box">
                    <div class="separator-role"></div>  
                    <div class="role-text-box top-padding-md bottom-margin-md">
                        <h4 class="" >Overview</h4>
                        <p class="body_text_sm bottom-padding-md">Wiz connect is a mobile application that allows users to connect, control, and automate their smart Wiz light bulbs. </p>
                        <h4 class="" >My Role</h4>
                        <p class="body_text_sm">I conducted user research interviews to understand user needs, identify main pain points, and find ways to improve the app. Finally, I iterated the interface design through 2 rounds of user testing, one A/B testing, and 2 surveys.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sticky progress bar with jump links and shrink effect on scroll  

tutorial links: 
        sticky bar      ------------           
        https://www.w3schools.com/howto/howto_js_navbar_sticky.asp
         https://therichpost.com/angular-8-sticky-navbar-working-example/
        shrink a header in scroll   -------     https://www.w3schools.com/howto/howto_js_shrink_header_scroll.asp
        jumping links         ---------         https://www.bennadel.com/blog/3402-creating-a-jump-to-anchor-fragment-polyfill-in-angular-5-2-0.htm   

-->

<div class="progress_bar_box ">
    <h2 class="h2title stiky_nav_title top-padding-lg">Design process</h2>
    <nav class="sticky_nav navbar"  ngStickyNav stickyClass="sticky-box-shadow" >
        <a href="#section1" [class.sticky_active]="selectedLink === 'one'" (click)="markSelected('one')" class="sticky_nav_item" target="_self">
            <div class="sticky_content">
                <div class="circle">1</div>
                <p class="progress_title">Research</p>
            </div>
        </a>
        <a href="#section2" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'two'" (click)="markSelected('two')"  target="_self">
            <div class="sticky_content">
                <div class="circle">2</div>
                <p class="progress_title">User test 1</p>
            </div>
        </a>
        <a href="#section3" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'three'" (click)="markSelected('three')"  target="_self">
            <div class="sticky_content">
                <div class="circle">3</div>
                <p class="progress_title">A/B Testing</p>
            </div>
        </a>
        <a href="#section4" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'four'" (click)="markSelected('four')"  target="_self">
            <div class="sticky_content">
                <div class="circle">4</div>
                <p class="progress_title">Preference test</p>
            </div>
        </a>
        <a href="#section5" class="sticky_nav_item " [class.sticky_active]="selectedLink === 'five'" (click)="markSelected('five')"  target="_self">
            <div class="sticky_content">
                <div class="circle">5</div>
                <p class="progress_title">User test 2</p>
            </div>
        </a>
    </nav>
</div>









<!-- Section 1 -->
<div class="white-bg" id="section1">
    <div class="container">
        <div class="grid">
            <div class="section-numb-1-l col-sm-12 col-lg-2">
                <h1 class="num1">1</h1>
                <div class="position-rel">
                    <div class="nub-titles-box">
                        <p class="num-subtitle text-highlight ">Interviewing the right </p>
                        <div class="num1-horizonal-line"></div>
                        <h2 class="num-title">Audience</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-1-r col-sm-12 col-lg-10 top-margin-lg">
                <div class="carousel" data-timer="3"> <!-- change this value to control picture holding period -->
                    <div class="carousel-deck">
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                        <div class="carousel-slide"></div>
                    </div>
                </div>
            </div>
        </div>
        <p class="top-padding-md">I identified the ideal personas for this project by investigating papers and market reports about emerging technologies for home. According to this article, the adoption of IoT devices at home has been growing as millennials and zoomers reach adulthood.</p>

        <p class="top-padding-md ">After interviewing two smart lights users, two users who don't own smart lights but have tried them at friends' houses and two who have different types of remote light controls, I identified the following problems: </p>
    </div>
</div>

<!-- Problem statement  -->
<div class="white-bg">
    <div class="container">
        <div class="grid">
            <div class="problem-statement-box col-sm-12 bottom-margin-lg top-margin-lg">
                <h4 class="text-highlight">Problem Statement</h4>
                <div class="statement-wrap">
                    <div class="quote-l">
                        <img  class="quote-l-img " src="../../assets/images/wizapp/top-quote.png">
                    </div>
                    <p class="statement-text">People don't use secondary functions because they are difficult to find in the app. In addition, tasks like changing the lights' color and setting a timer are time-consuming and cumbersome.</p>
                    <div class="quote-r">
                        <img  class="quote-r-img " src="../../assets/images/wizapp/bottom-quote.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Subtitle 2 wiz-->
<div id="section2" class="white-bg">
    <div class="grid">
        <div class="section-numb-2-r col-sm-12 col-lg-6">
            <div class="num2-title-container">
                <h1 class="num2">2</h1>
                <div class="nub2-titles-box">
                    <p class="num2-subtitle text-highlight ">Trying low-fidelity mockups</p>
                    <div class="num2-horizonal-line"></div>
                    <h2 class="num2-title">Task tests</h2>
                </div>
            </div>
        </div>
        <div class="section-numb-2-l col-sm-12 col-lg-6 ">
            <p class="terciary-title">Smart lights have many features, but after the first round of interviews, I focused on the essential tasks and redesigned the user interface entirely. The first round of user testing was to identify the ideal layout. </p>
            <p class="text-bold top-padding-sm">Tasks</p>
            <ul>
                <li>Turn on a light.</li>
                <li>Set a 15 min timer on the light.</li>
                <li>Change the color of the light.</li>
            </ul>
            <p class="top-padding-sm">All sessions were handed over zoom for 30min on average. Here are the top 2 highlights from the user testing results:</p>
        </div>
    </div>
</div>
<div class="white-bg">
    <div class="container top-padding-md bottom-padding-md white-bg">
        <h3 class="bottom-padding-md">Findings and learnings</h3>
        <div>
            <img  class="image-contain " src="../../assets/images/wizapp/iteration1.png">
        </div>
    </div>
</div>
<div class="wiz-life2-img-box"></div>


<!-- Subtitle 3 wiz-->
<div id="section3" class="section3-bg">
    <div class="container">
        <div class="grid">
            <div class="section-numb-3-l col-sm-12 col-lg-4">
                <h1 class="num3">3</h1>
                <div class="position-rel">
                    <div class="nub3-titles-box">
                        <p class="num3-subtitle text-highlight ">Deciding details with</p>
                        <div class="num3-horizonal-line"></div>
                        <h2 class="num3-title">A/B Testing</h2>
                    </div>
                </div>
            </div>
            <div class="section-numb-3-r col-sm-12 col-lg-8 top-margin-lg">
                <p>The next step for this project was to get feedback about how well the app communicates with users and how this interface could improve. A 5-10 min A/B testing was prepared using google forms for this assessment. I got around 15 responses full of notes and comments that I used to determine which design direction to take. The following images represent a small section of the A/B testing results.</p>
                <p>Look at the following A and B screenshots: </p>
                <p><span class="text-bold">Q1.</span>Which one communicates the color of the light better?</p>
                <img class="ab-image" src="../../assets/images/wizapp/abimage.png" alt="ab test image" >
            </div>
        </div>
    </div>
    <div class="container ">
        <div class="role-box top-margin-md bottom-margin-md">
            <div class="separator-role"></div>  
            <div class="role-text-box top-margin-md bottom-margin-md">
                <h4 class="text-secondary bottom-margin-md" >Key takeaways from the A/B testing</h4>
                <ul>
                    <li><span class="body_text_md text-bold ">60%</span>  of the respondents think that version B communicates better the color of light. </li>
                    <li>Most people prefer big and evident light color indicators instead of small and subtle detail; someone suggested making the light spectrum even bigger.</li>
                    <li>B design was positively taken as ambient light.</li>
                </ul>
            </div>
        </div>
        <div class="iteration2-box ">
            <h3 class="bottom-padding-md">UI improvements after A/B testing</h3>
            <div>
                <img  class="image-contain " src="../../assets/images/wizapp/iteration2.png">
            </div>
        </div>
    </div>  
</div>
<div class="section4-5-bg">


    <!-- Subtitle 4 wiz-->

    <div  id="section4"  class="section4-bg ">
        <div class="container">
            <div class="grid">
                <div class="section-numb-4-l col-sm-12 col-lg-4">
                    <h1 class="num4">4</h1>
                    <div class="position-rel">
                        <div class="nub4-titles-box">
                            <p class="num4-subtitle text-highlight ">Asking user preference</p>
                            <div class="num4-horizonal-line"></div>
                            <h2 class="num4-title">Light vs Dark</h2>
                        </div>
                    </div>
                </div>
                <div class="section-numb-4-r col-sm-12 col-lg-8 top-margin-lg">
                    <img class="ab-image" src="../../assets/images/wizapp/themes.png" alt="ab test image" >
                    <p class="top-padding-md bottom-padding-md">Once most of the app had a great layout and structure, it was time to select a color pallet and bring excitement to the interface. I decided to do a quick preference test on Instagram, showing two versions of the UI; one with a light theme and the other with a dark theme. The following results helped me to learn and understand the current users' color preferences. 70% of the participants liked the dark theme over the light one. </p>
                </div>
            </div>
        </div> 
    </div>


    <!-- Subtitle 5 wiz-->
    <div id="section5"  class=""> 
        <div class="container">
            <div class="grid">
                <div class="section-numb-5-l col-sm-12 col-lg-8">
                    <div>
                        <iframe  id='invision' width="442" height="935" src="//invis.io/AF106B63UVJZ" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="section-numb-5-r col-sm-12 col-lg-4">
                    <div class="num5-title-container ">
                        <h1 class="num5">5</h1>
                        <div class="nub5-titles-box">
                            <p class="num5-subtitle text-highlight ">Validating design with </p>
                            <div class="num5-horizonal-line"></div>
                            <h2 class="num5-title">Final feedback</h2>
                        </div>
                    </div>
                    <div class="section5-content-wrap">
                        <p class="bottom-padding-sm">The last round of user interviews was dedicated to previous follow-up feedback and validating the final design. </p>
                        <img  class="image-contain " src="../../assets/images/wizapp/particiapnts.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="wiz-final-hero-section" id="section6">
    <img  class="image-contain wiz-final-hero" src="../../assets/images/wizapp/final-hero.png">
</div>
<!-- foolter -->
<div class="wiz-footer-bg " style="background-color: #FEFCFF;">
    <div class="container flex-row-between-center wiz-footer-box">
        <div class="social-media flex-row-center-center bottom-padding-md">
            <p class="myname-footer">Kimi Kurata</p>
            <hr class="footer-separator-a"/>
            <a class="visitme_link" href="https://www.linkedin.com/in/mari-kurata" target="_blank"  >Linkedin</a>
            <hr class="footer-separator-b"/>
            <a class="visitme_link" href="../../assets/resume/kimi_kurata_resume.pdf" download>Resume</a>
        </div>
        <div class="wiz-footer-vl flex-row-start-end ">
            <div class="vl-gradient wiz-vlfooter"></div>
            <p class="body_text_xsm veritical-text bottom-padding-md wiz-copyright">&copy;copyright 2022 </p>
        </div>
    </div>
</div>